import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Folder, File, ChevronRight, ChevronDown } from 'lucide-react'
import { useAppSelector, useAppDispatch } from '../../lib/hooks'
import { selectToken } from '../../lib/features/auth/authSlice'
import FileTree from './FileTree'
import ChatHeader from './ChatHeader'
import throttle from "lodash/throttle";

const BE_HOST = process.env.REACT_APP_BE_HOST;
const BASE_URL: string = BE_HOST ? BE_HOST : "https://snsys-qa.neoali.com";
const APP_STAGE = process.env.REACT_APP_STAGE;
// PDF 리스트 컴포넌트
interface PDFListProps {
  files: string[];
  onSelect: (file: string) => void;
}

const PDFList: React.FC<PDFListProps> = ({ files, onSelect }) => (
  <ul className="divide-y divide-gray-200">
    {files.map((file) => (
      <li key={file} className="py-2">
        <button
          onClick={() => onSelect(file)}
          className="text-sm hover:text-blue-500"
        >
          {file}
        </button>
      </li>
    ))}
  </ul>
)

// PDF 뷰어 컴포넌트 (실제 구현은 별도의 라이브러리 필요)
interface PDFViewerProps {
  file: string | null;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ file }) => (
  <div className="bg-gray-100 p-4 rounded">
    <h2 className="text-lg font-semibold mb-2">PDF Viewer</h2>
    <p>Viewing: {file}</p>
    {/* 여기에 실제 PDF 뷰어 구현 필요 */}
  </div>
)


interface Pdf {
  pdf_id: number;
  original_nm: string;
  file_path: string;
  file_size: number;
  pdf_url: string;
}

interface Chatbot {
  chatbot_id: number;
  pgroup: string;
  lang: string;
  pdfs: Pdf[];
}
interface CGroup {
  cgroup_name: string;
  cgroup_type: string;
  chatbots: Chatbot[];
}

type TreeNode = {
  fgroup_id?: string
  fgroup_name?: string
  pdf_id?: string
  pdf_name?: string
  isFolder: boolean
  child?: TreeNode[]
  files?: TreeNode[]
}



// 메인 컴포넌트
export default function PDFFileViewer() {
  const token = useAppSelector(selectToken)
  const dispatch = useAppDispatch()

  const [fileStructure, setFileStructure] = useState<TreeNode | null>(null)
  const [selectedFile, setSelectedFile] = useState<TreeNode | null>(null)
  const [isOpen, setIsOpen] = useState(true)
  // const handleSelect = (file: FileStructure) => {
  //   console.log({name:file})
  //   if (file.name.endsWith('.pdf')) {
  //     setSelectedFile(file)
  //   } else {
  //     // 폴더 선택 시 해당 폴더의 PDF 파일들을 리스트에 추가
  //     // const folderPdfs = findPDFsInFolder(fileStructure, file)
  //     // setPdfFiles([...new Set([...pdfFiles, ...folderPdfs])])
  //   }
  // }

  function organizePdfsByPgroupName(data: any) {
    const result: CGroup[] = data.map((item: any) => {

      const CGroup = {
        cgroup_name: item.cgroup_name,
        cgroup_type: item.cgroup_type,
        chatbots: item.chatbots.map((chatbot: any) => {
          return {
            chatbot_id: chatbot.chatbot_id,
            pgroup: chatbot.pgroup.pgroup_id,
            lang: chatbot.pgroup.pgroup.pgroup_name,
            pdfs: chatbot.pgroup.pdf.map((pdf: any) => {
              return {
                pdf_id: pdf.pdf_id,
                original_nm: pdf.pdf_display_nm,
                file_path: pdf.pdf_path,
                file_size: pdf.pdf_size,
                pdf_url: "http://"
              }
            })
          }
        }
        )
      }
      return CGroup;

    });

    return result;
  }

  // const fileStructure: FileStructure[] = [
  //   {
  //     name: 'Documents',
  //     type: 'folder',
  //     children: [
  //       { name: 'Report.pdf', type: 'file' },
  //       { name: 'Invoice.pdf', type: 'file' },
  //     ],
  //   },
  //   {
  //     name: 'Images',
  //     type: 'folder',
  //     children: [
  //       { name: 'Diagram.pdf', type: 'file' },
  //     ],
  //   },
  //   { name: 'README.pdf', type: 'file' },
  // ]

  // Conversion function
  function convertToTreeNode(inputNode: any): TreeNode {
    // Folder Node Conversion
    const folderNode: TreeNode = {
      fgroup_id: inputNode.fgroup_id?.toString(),
      fgroup_name: inputNode.fgroup_name,
      isFolder: true,
      child: [],
      files: []
    };

    // File Conversion within folderNode
    inputNode.files?.forEach((file: any) => {
      const fileNode: TreeNode = {
        pdf_id: file.pdf_id.toString(),
        pdf_name: file.pdf_display_nm,
        isFolder: false
      };
      folderNode.child?.push(fileNode);
    });

    // Recursively convert children
    inputNode.child?.forEach((childNode: any) => {
      folderNode.child?.push(convertToTreeNode(childNode));
    });

    return folderNode;
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(BASE_URL + '/api/file/fgroup/files', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token as string,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }

        const jsonData = await response.json();
        if (APP_STAGE === "DEV") {
          // console.log('Success:', jsonData);
        }
        // const organizedData = organizePdfsByPgroupName(jsonData);
        // setOrganizedPdfs(organizedData);
        // console.log('Success:', organizedData);
        // saveFileStructure(organizedData);
        const treeNode = convertToTreeNode(jsonData.file_tree);
        if (APP_STAGE === "DEV") {
          console.log({ treeNode })
        }
        setFileStructure(treeNode)
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  const handleFolderSelect = (node: TreeNode) => {
    // setSelectedFolder(node)
    // handleGetDirFile(parseInt(node.fgroup_id)) 
  }
  const handleFileSelect = (node: TreeNode) => {
    setSelectedFile(node)
  }
  if (APP_STAGE === "DEV") {
    // console.log({ selectedFile })
  }

  const [leftWidth, setLeftWidth] = useState(25); // 실제 영역 비율 (%)
  const [overlayWidth, setOverlayWidth] = useState(leftWidth); // 투명 바 비율 (%)
  const [isDragging, setIsDragging] = useState(false);

  const OutFrameRef = useRef<HTMLDivElement | null>(null);

  const handleMouseDown = () => {
    setOverlayWidth(leftWidth); // 초기 위치
    setIsDragging(true); // 드래그 시작
  };

  const handleMouseMove = throttle((e: MouseEvent) => {
    if (isDragging) {
      const outFrameRect = OutFrameRef.current?.getBoundingClientRect();
      if (outFrameRect) {
        let newWidth = ((e.clientX - outFrameRect.left) / outFrameRect.width) * 100;
        newWidth = Math.min(75, Math.max(25, newWidth)); // 제한 범위 적용 (25~75%)
        setOverlayWidth(newWidth); // 투명 바 갱신
      }
    }
  }, 16);

  const handleMouseUp = () => {
    if (isDragging) {
      setLeftWidth(overlayWidth); // 드래그 종료 후 실제 영역 비율 업데이트
      setIsDragging(false); // 드래그 종료
    }
  };

  // 이벤트 리스너 관리
  React.useEffect(() => {
    const handleGlobalMouseUp = () => {
      handleMouseUp();
    };

    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleGlobalMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleGlobalMouseUp);
    };
  }, [isDragging, overlayWidth]);
  
  return (
    <main id="chatMain" className="relative min-h-screen flex-1 bg-gray-50 p-2 sm:p-6">
    <ChatHeader />
    <div className="flex w-full h-screen">
      <div
        className="flex flex-col w-full h-screen overflow-hidden sm:flex-row relative"
        ref={OutFrameRef}
      >
        {/* 왼쪽 패널 */}
        <div
          className="bg-white p-4 border-r border-gray-200 shadow-md hidden sm:block"
          style={{ width: `${leftWidth}%` }} // 실제 비율
        >
          <h1 className="text-xl font-bold mb-6 text-blue-800">MANUALS</h1>
          <div className="w-full h-full pb-8 overflow-y-auto select-none">
            {fileStructure && (
              <FileTree
                data={[fileStructure] as TreeNode[]}
                onSelect={handleFolderSelect}
                onFileSelect={handleFileSelect}
                selectedId={null}
              />
            )}
          </div>
        </div>

        {/* 투명 바 */}
        {isDragging && (
          <div
            className="absolute top-0 left-0 h-full bg-blue-500 bg-opacity-30 z-20 pointer-events-none"
            style={{ width: `${overlayWidth}%` }}
          />
        )}

        {/* 리사이저 */}
        <div
          onMouseDown={handleMouseDown}
          className="w-1 cursor-col-resize bg-gray-300 hover:bg-gray-400 hover:cursor-col-resize hidden sm:block"
        />

        {/* 오른쪽 패널 */}
        <div
          className={`!w-full sm:w-full flex-1 bg-gray-100 ${isDragging ? "select-none" : ""}`}
          style={{ width: `${100 - leftWidth}%` }}
        >
          <div className="flex-1 w-full p-2 bg-gray-100">
            {selectedFile ? (
              <div className="w-full h-full pb-4 rounded-lg shadow-lg bg-white overflow-hidden">
                <h6 className="text-center font-semibold text-gray-700 py-2 border-b border-gray-300">
                  {selectedFile.pdf_name}
                </h6>
                <iframe
                  className="w-full h-screen"
                  src={`/pdf-view/viewer.html?file=${BASE_URL}/api/file/pdf/download/${selectedFile?.pdf_id}`}
                  title={selectedFile?.pdf_name}
                  key={selectedFile?.pdf_id}
                />
              </div>
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-center text-gray-500 mt-10">Select a PDF file to view</p>
              </div>
            )}
          </div>
        </div>

        {isDragging && (
          <div
            className="absolute top-0 left-0 w-full h-full z-10 bg-transparent"
            style={{ pointerEvents: "auto" }}
          />
      )}
      </div>
    </div>
    <div className={`absolute bottom-0 w-full bg-white p-2 border-r border-gray-200 shadow-md pb-4 sm:hidden ${isOpen ? 'h-1/2' : 'h-0'} transition-all duration-300 `}>
        {/* <h1 className="text-xl font-bold mb-6 text-blue-800">MANUALS</h1> */}
        <div 
          onClick={()=>setIsOpen(!isOpen)}
          className={`flex justify-center items-center border-gray-200 pb-2 mb-2 cursor-pointer ${isOpen ? 'border-b border-t' : 'border-t'}`}>
          <ChevronDown className={`w-6 h-6 transition-transform duration-300 ${!isOpen ? 'rotate-180' : ''}`} />
        </div>
        <div
          className="w-full h-full p-4  overflow-y-auto"
        >
          {fileStructure &&<div className="w-full pb-4">
              <FileTree
                data={[fileStructure] as TreeNode[]}
                onSelect={handleFolderSelect}
                onFileSelect={handleFileSelect}
                selectedId={null}
              />
          </div>}
        </div>
      </div>
  </main>
);
}
