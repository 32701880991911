import {
  AdminGet,
  AdminGroupAdd,
  AdminGroupUpdate,
  AdminGroupDelete,
  AdminModelAdd,
  AdminUserCompanyAdd,
  AdminGroupChange,
  AdminModelUpdate,
  AdminModelDelete,
  AdminFileGetOnfid,
  AdminFileAdd,
  AdminFileUpdate,
  AdminCompanyChange,
  AdminCompanyUpdate,
  AdminCompanyDelete,
  AdminFileDelete,
  AdminFGroupAdd,
  AdminFGroupUpdate,
  AdminFGroupDelete,
  AdminPGroupAdd,
  AdminMessageGet,
  AdminPGroupUpdate,
  AdminPGroupDelete,
  AdminPGroupFileGet,
  AdminPGroupFileAdd,
  AdminChatbotAdd,
  AdminChatbotDelete,
  AdminChatbotGroupAdd,
  AdminChatbotGroupUpdate,
  AdminChatbotGroupDelete,
  AdminUserEmailLockUpdate,
  AdminUserUpdate,
  AdminVesselRequest,
  AdminVesselDelete,

  AdminKeywordRequest,
  AdminUserSignUpRequest,
  AdminKeywordDelete,
  AdminUserEmailSend,
  AdminFaqRequest,
  AdminFaqDelete,
  AdminPicRequest,
  AdminPicDelete,
  AdminServiceReqUpdate,
  AdminUserCreateByAdmin,
  AdminUserDelete
} from "./adminSlice";

const APP_STAGE = process.env.REACT_APP_STAGE;
const BE_HOST = process.env.REACT_APP_BE_HOST;
// console.log(BE_HOST);
// const BASE_URL:string = 'http://155.230.135.140:50005';
const BASE_URL: string = BE_HOST ? BE_HOST : "https://snsys-dev.neoali.com";
// const BASE_URL: string = "http://localhost:12345";

// Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOjIsImp0aSI6Im1OdkhjZlJsRkwiLCJpYXQiOjE3MjA0MTQxOTYsImV4cCI6MTc1MTk1MDE5Nn0.m5MbZMy3jYB71ba3Trqb7nBqGGq4MCw9VQbFluQnXhE
// A mock function to mimic making an async request for data

export const fetchAdminUserEamilSend = async (
  adminUserEmailsendData: AdminUserEmailSend
) => {
  if (APP_STAGE === "DEV") {  
    console.log(adminUserEmailsendData);
  }
  const response = await fetch(BASE_URL + `/api/auth/user/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUserEmailsendData.token,
    },
    body: JSON.stringify(adminUserEmailsendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminUserReSendEmail = async (
  adminUserReSendEmailData: {token: string, userId: number}
) => {
  if (APP_STAGE === "DEV") {  
    console.log(adminUserReSendEmailData);
  }
  const response = await fetch(BASE_URL + `/api/auth/user/resend-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUserReSendEmailData.token,
    },
    body: JSON.stringify({user_id: adminUserReSendEmailData.userId}),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminUserInviteEmail = async (
  adminUserInviteEmailData: {token: string, userId: number}
) => {
  if (APP_STAGE === "DEV") {  
    console.log(adminUserInviteEmailData);
  }
  const response = await fetch(BASE_URL + `/api/auth/user/invite-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUserInviteEmailData.token,
    },
    body: JSON.stringify({user_id: adminUserInviteEmailData.userId}),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminRoomGetUser = async (data: AdminMessageGet) => {
  const response = await fetch(
    BASE_URL + `/api/chatbot/room/user/${data.user_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: data.token,
      },
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminCompanyChange = async (data: AdminCompanyChange) => {
  const response = await fetch(BASE_URL + `/api/admin/company/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: data.token,
    },
    body: JSON.stringify({
      user_id: data.user_id,
      company_id: data.company_id,
    }),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminGroupChange = async (data: AdminGroupChange) => {
  const response = await fetch(BASE_URL + `/api/user/group/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: data.token,
    },
    body: JSON.stringify({
      user_id: data.user_id,
      group_id: data.group_id,
    }),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminCompanyAdd = async (
  adminGetData: AdminUserCompanyAdd
) => {
  const response = await fetch(BASE_URL + `/api/admin/company`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    body: JSON.stringify({ company_name: adminGetData.company }),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminCompany = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/admin/company", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminCompanyUpdate = async (adminGetData: AdminCompanyUpdate) => {
  const response = await fetch(BASE_URL + `/api/admin/company/${adminGetData.company_id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    body: JSON.stringify({company_name: adminGetData.company_name}),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminCompanyDelete = async (adminGetData: AdminCompanyDelete) => {
  const response = await fetch(BASE_URL + `/api/admin/company/${adminGetData.company_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};


export const fetchAdminSignUpRequests = async (
  adminGetData: AdminUserSignUpRequest
) => {
  const response = await fetch(BASE_URL + `/api/auth/register`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminFileUpload = async (fileUploadData: {
  token: string;
  fileData: FormData;
  ctype?: string;
  // chatbotId: number;
}) => {
  const response = await fetch(
    // BASE_URL + `/api/file/faq/upload/${fileUploadData.chatbotId}`,
    BASE_URL + `/api/file/faq/upload/?ctype=${fileUploadData?.ctype}`,
    {
      method: "POST",
      headers: {
        Authorization: fileUploadData.token,
      },
      body: fileUploadData.fileData,
    }
  );

  const result: any = await response.json();
  return result;
};

export const fetchAdminFileKeywordUpload = async (fileUploadData: {
  token: string;
  fileData: FormData;
  // chatbotId: number;s
}) => {
  const response = await fetch(BASE_URL + `/api/chatbot/keyword/upload`, {
    method: "POST",
    headers: {
      Authorization: fileUploadData.token,
    },
    body: fileUploadData.fileData,
  });

  const result: any = await response.json();
  return result;
};

// 유저 삭제
export const fetchAdminUserDel = async (
  adminUserUpdateData: AdminUserEmailLockUpdate
) => {
  const response = await fetch(BASE_URL + "/api/auth/del", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUserUpdateData.token,
    },
    body: JSON.stringify({
      userId: adminUserUpdateData.userId,
    }),
  });

  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

// 유저 업데이트
export const fetchAdminUserUpdate = async (
  adminUserUpdateData: AdminUserUpdate
) => {
  const response = await fetch(BASE_URL + `/api/auth/${adminUserUpdateData.userId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUserUpdateData.token,
    },
    body: JSON.stringify(adminUserUpdateData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  const result: any = await response.json();

  return { status: response.status, result }; ;
};

// 유저 삭제
export const fetchAdminUserDelete = async (
  adminUserDeleteData: AdminUserDelete
) => {
  const response = await fetch(BASE_URL + `/api/auth/delete`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUserDeleteData.token,
    },
    body: JSON.stringify({ userId: adminUserDeleteData.userId }),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};


// 이메일 체크 업데이트
export const fetchAdminEmailUpdate = async (
  adminUserEmailUpdateData: AdminUserEmailLockUpdate
) => {
  const response = await fetch(BASE_URL + "/api/admin/email/update/check", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUserEmailUpdateData.token,
    },
    body: JSON.stringify({ userId: adminUserEmailUpdateData.userId }),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

//lock 업데이트
export const fetchAdminLockUpdate = async (
  adminUserEmailUpdateData: AdminUserEmailLockUpdate
) => {
  const response = await fetch(BASE_URL + "/api/admin/update/lock", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUserEmailUpdateData.token,
    },
    body: JSON.stringify({ userId: adminUserEmailUpdateData.userId }),
  });

  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminFaqFinetune = async (adminGetData: AdminGet, ctype: string) => {
  const response = await fetch(BASE_URL + `/api/file/faq/finetune?ctype=${ctype}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  const result: any = await response.json();
  if(APP_STAGE === "DEV"){
    console.log(result)
  }

  return result;
};



export const fetchAdminAdminKeywords = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/chatbot/keyword", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminTestFeedbacks = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/chatbot/test/feedback", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};


export const fetchAdminAdminKeywordAdd = async (
  adminkeywordRequestData: AdminKeywordRequest
) => {
  const response = await fetch(BASE_URL + "/api/chatbot/keyword/add", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminkeywordRequestData.token,
    },
    body: JSON.stringify(adminkeywordRequestData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminApilogs = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/admin/user/apilog", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

// export const fetchAdminAdminFeedbackTexts = async (adminGetData: AdminGet) => {
//   const response = await fetch(BASE_URL + "/api/admin/user/apilog", {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: adminGetData.token,
//     },
//     // body: JSON.stringify(messageSendData),
//   });
// console.log(response.json())
// console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
//   const result: any = await response.json();

//   return result;
// };

export const fetchAdminAdminFeedbackLikes = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/admin/user/feedback", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminEmailModeList = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/admin/user/eml", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminUserAllGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/admin/user/all", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminGroupListGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/user/group", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminGroupAdd = async (adminAddData: AdminGroupAdd) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminAddData.token })
  const response = await fetch(BASE_URL + "/api/user/group", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminAddData.token,
    },
    body: JSON.stringify({
      group_name: adminAddData.groupName,
      group_description: adminAddData.groupDesc,
      is_admin: adminAddData.is_admin,
      is_chatbot_ts: adminAddData.is_chatbot_ts,
      is_chatbot_mt: adminAddData.is_chatbot_mt,
      is_chatbot_gi: adminAddData.is_chatbot_gi,
      is_main_manual: adminAddData.is_main_manual,
      is_main_video: adminAddData.is_main_video,
    }),
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminKeywordDelete = async (
  adminDeleteData: AdminKeywordDelete
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/chatbot/keyword/" + adminDeleteData.keyword_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminAdminFaqs = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/file/faq/all", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminAdminFaqAdd = async (
  adminFaqRequestData: AdminFaqRequest
) => {
  const response = await fetch(BASE_URL + "/api/file/faq", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminFaqRequestData.token,
    },
    body: JSON.stringify(adminFaqRequestData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminFaqDelete = async (adminDeleteData: AdminFaqDelete) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/file/faq/" + adminDeleteData.faq_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminPicUpload = async (fileUploadData: {
  token: string;
  fileData: FormData;
  // chatbotId: number;
}) => {
  const response = await fetch(
    BASE_URL + `/api/admin/personincharge/upload/`,
    {
      method: "POST",
      headers: {
        Authorization: fileUploadData.token,
      },
      body: fileUploadData.fileData,
    }
  );

  const result: any = await response.json();
  return result;
};


export const fetchAdminAdminPicListGet = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/admin/personincharge", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminPicAdd = async (
  adminPicRequestData: AdminPicRequest
) => {
  const response = await fetch(BASE_URL + "/api/admin/personincharge", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminPicRequestData.token,
    },
    body: JSON.stringify(adminPicRequestData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminPicDelete = async (adminDeleteData: AdminPicDelete) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/admin/personincharge/" + adminDeleteData.pic_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminAdminVesselListGet = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/admin/personincharge/vessel", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
    // body: JSON.stringify(messageSendData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminVesselAdd = async (
  adminVesselRequestData: AdminVesselRequest
) => {
  const response = await fetch(BASE_URL + "/api/admin/personincharge/vessel", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminVesselRequestData.token,
    },
    body: JSON.stringify(adminVesselRequestData),
  });
  // console.log(response.json())
  // console.log({ "Content-Type": "application/json", 'Authorization': adminGetData.token })
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminVesselDelete = async (adminDeleteData: AdminVesselDelete) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/admin/personincharge/vessel/" + adminDeleteData.vessel_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminGroupDelete = async (
  adminDeleteData: AdminGroupDelete
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/user/group/" + adminDeleteData.groupId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminGroupUpdate = async (
  adminUpdateData: AdminGroupUpdate
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminAddData.token })
  const response = await fetch(
    BASE_URL + "/api/user/group/" + adminUpdateData.groupId,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminUpdateData.token,
      },
      body: JSON.stringify({
        group_name: adminUpdateData.groupName,
        group_desc: adminUpdateData.groupDesc,
        is_admin: adminUpdateData.is_admin,
        is_chatbot_ts: adminUpdateData.is_chatbot_ts,
        is_chatbot_mt: adminUpdateData.is_chatbot_mt,
        is_chatbot_gi: adminUpdateData.is_chatbot_gi,
        is_main_manual: adminUpdateData.is_main_manual,
        is_main_video: adminUpdateData.is_main_video,
      }),
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminModelListGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/model", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminModelAdd = async (adminAddData: AdminModelAdd) => {
  // console.log("fetchAdminModelAdd");
  // console.log(adminAddData);
  const response = await fetch(BASE_URL + "/api/model", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminAddData.token,
    },
    body: JSON.stringify({
      model_name: adminAddData.modelName,
      description: adminAddData.description,
      version: adminAddData.version,
      model_method: adminAddData.modelMethod,
    }),
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminModelDelete = async (
  adminDeleteData: AdminModelDelete
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/model/" + adminDeleteData.modelId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminModelUpdate = async (
  adminUpdateData: AdminModelUpdate
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminAddData.token })
  const response = await fetch(
    BASE_URL + "/api/model/" + adminUpdateData.modelId,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminUpdateData.token,
      },
      body: JSON.stringify({
        model_name: adminUpdateData.modelName,
        description: adminUpdateData.description,
        version: adminUpdateData.version,
        model_method: adminUpdateData.modelMethod,
      }),
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminFileListGet = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/file/pdf", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  const result: any = await response.json();
  // console.log(result);

  return result;
};

export const fetchAdminFileListGetOnfid = async (
  adminGetData: AdminFileGetOnfid
) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(
    BASE_URL + "/api/file/pdf?fgroupId=" + adminGetData.fgroupId,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminGetData.token,
      },
    }
  );
  const result: any = await response.json();
  // console.log(result);

  return result;
};

export const fetchAdminFileAdd = async (adminAddData: AdminFileAdd) => {
  // console.log("fetchAdminFileAdd");
  // console.log(adminAddData);
  const myHeaders = new Headers();
  myHeaders.append("Authorization", adminAddData.token);
  const formdata = new FormData();
  adminAddData.files.map((file: any) => formdata.append("files", file));
  formdata.append("mode", "pdf");
  formdata.append("chatbot_model_id", "2");
  formdata.append("retriever_model_id", "1");
  // console.log(formdata);
  const response = await fetch(BASE_URL + "/api/file/pdf/upload", {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  });
  const result: any = await response.json();
  // console.log(result);

  return result;
};
export const fetchAdminFileUpdate = async (
  adminUpdateData: AdminFileUpdate
) => {
  const response = await fetch(
    BASE_URL + "/api/file/pdf/" + adminUpdateData.fileId,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminUpdateData.token,
      },
      body: JSON.stringify({
        original_nm: adminUpdateData.fileName,
      }),
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminFileDelete = async (
  adminDeleteData: AdminFileDelete
) => {
  const response = await fetch(
    BASE_URL + "/api/file/pdf/" + adminDeleteData.fileId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminFGroupAllListGet = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/file/fgroup/files", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminFGroupListGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/file/fgroup", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminFGroupAdd = async (adminAddData: AdminFGroupAdd) => {
  // console.log("fetchAdminModelAdd");
  // console.log(adminAddData);
  const response = await fetch(BASE_URL + "/api/file/fgroup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminAddData.token,
    },
    body: JSON.stringify({
      fgroup_name: adminAddData.fgroup_name,
      fgroup_pid: adminAddData.fgroup_pid,
    }),
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminFGroupDelete = async (
  adminDeleteData: AdminFGroupDelete
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/file/fgroup/" + adminDeleteData.fgroupId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminFGroupUpdate = async (
  adminUpdateData: AdminFGroupUpdate
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminAddData.token })
  const response = await fetch(
    BASE_URL + "/api/file/fgroup/" + adminUpdateData.fgroupId,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminUpdateData.token,
      },
      body: JSON.stringify({
        fgroup_name: adminUpdateData.fgroupName,
      }),
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminPGroupListGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/file/pgroup", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminPGroupAdd = async (adminAddData: AdminPGroupAdd) => {
  // console.log("fetchAdminModelAdd");
  // console.log(adminAddData);
  const response = await fetch(BASE_URL + "/api/file/pgroup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminAddData.token,
    },
    body: JSON.stringify({
      pgroup_name: adminAddData.pgroupName,
    }),
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminPGroupDelete = async (
  adminDeleteData: AdminPGroupDelete
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/file/pgroup?pgroupId=" + adminDeleteData.pgroupId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminPGroupUpdate = async (
  adminUpdateData: AdminPGroupUpdate
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminAddData.token })
  const response = await fetch(
    BASE_URL + "/api/file/pgroup?pgroupId=" + adminUpdateData.pgroupId,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminUpdateData.token,
      },
      body: JSON.stringify({
        pgroup_name: adminUpdateData.pgroupName,
      }),
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminPGroupFileListGet = async (
  adminGetData: AdminPGroupFileGet
) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(
    BASE_URL + "/api/file/pgroup/id?pgroupId=" + adminGetData.pgroup_id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminGetData.token,
      },
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminPGroupFileAdd = async (
  adminAddData: AdminPGroupFileAdd
) => {
  // console.log("fetchAdminModelAdd");
  // console.log(adminAddData);
  const response = await fetch(
    BASE_URL + "/api/file/pgroup/id?pgroupId=" + adminAddData.pgroup_id,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminAddData.token,
      },
      body: JSON.stringify({
        append_files: adminAddData.fids,
      }),
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminChatbotListGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/chatbot/bot", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminChatbotAdd = async (adminAddData: AdminChatbotAdd) => {
  // console.log("fetchAdminModelAdd");
  // console.log(adminAddData);
  const response = await fetch(BASE_URL + "/api/chatbot/bot", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminAddData.token,
    },
    body: JSON.stringify({
      pgroup_id: adminAddData.pgroupId,
      model_id: adminAddData.modelId,
    }),
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminChatbotDelete = async (
  adminDeleteData: AdminChatbotDelete
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/chatbot/bot?chatbotId=" + adminDeleteData.chatbotId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminChatbotGroupListGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/chatbot/group", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminChatbotGroupAdd = async (
  adminAddData: AdminChatbotGroupAdd
) => {
  // console.log("fetchAdminModelAdd");
  // console.log(adminAddData);
  const response = await fetch(BASE_URL + "/api/chatbot/group", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminAddData.token,
    },
    body: JSON.stringify({
      group_id: adminAddData.group_id,
      model_id: adminAddData.cgroup_name,
      cgroup_type: adminAddData.cgroup_type,
      chatbot_en_id: adminAddData.chatbot_en_id,
    }),
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};
export const fetchAdminChatbotGroupUpdate = async (
  adminUpdateData: AdminChatbotGroupUpdate
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminUpdateData.token })
  const response = await fetch(
    BASE_URL + "/api/chatbot/group?cgroupId=" + adminUpdateData.cgroup_id,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminUpdateData.token,
      },
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminChatbotGroupDelete = async (
  adminDeleteData: AdminChatbotGroupDelete
) => {
  // console.log({ "Content-Type": "application/json", 'Authorization': adminDeleteData.token })
  const response = await fetch(
    BASE_URL + "/api/chatbot/group?cgroupId=" + adminDeleteData.cgroup_id,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: adminDeleteData.token,
      },
    }
  );
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminRoomListGet = async (adminGetData: AdminGet) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/chatbot/room", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminServiceListGet = async (adminGetData: AdminGet) => {
  const response = await fetch(BASE_URL + "/api/admin/service", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminServiceUpdate = async (adminUpdateData: AdminServiceReqUpdate) => {
  const response = await fetch(BASE_URL + "/api/admin/service/" + adminUpdateData.service_req_id, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUpdateData.token,
    },
    body: JSON.stringify({
      status: adminUpdateData.status,
    }),
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};

export const fetchAdminUserCreateByAdmin = async (adminUpdateData: AdminUserCreateByAdmin) => {
  const response = await fetch(BASE_URL + "/api/auth/user/create-new", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminUpdateData.token,
    },
    body: JSON.stringify(adminUpdateData),
  });

  if (!response.ok) {
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};


export const fetchAdminChatDetail = async (adminGetData: {token: string, room_id: number}) => {
  // console.log({
  //   "Content-Type": "application/json",
  //   Authorization: adminGetData.token,
  // });
  const response = await fetch(BASE_URL + "/api/admin/message/user/" + adminGetData.room_id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: adminGetData.token,
    },
  });
  if (!response.ok) {
    // HTTP 상태 코드가 200~299가 아닐 경우 예외를 던짐
    const error = await response.json();
    throw { status: response.status, message: error.message  || 'Unknown error' };
  }

  return response.json();
};