import React, { useEffect, useRef, useState } from 'react'
import { ChevronRight, ChevronDown, Folder, File } from 'lucide-react'
import { useAppDispatch, useAppSelector } from "../../lib/hooks"
import {
  adminUserAllGet,
  adminGroupListGet,
  adminModelListGet,
  adminFileListGet,
  adminFGroupListGet,
  adminFileListGetOnfid,
  adminPGroupListGet,
  adminPGroupFileListGet,
  adminChatbotListGet,
  adminPGroupAdd,
  adminPGroupDelete,
  adminFileAdd,
  adminFileDelete,
  selectDashboardUser,
  selectDashboardGroup,
  selectDashboardModel,
  selectDashboardFile,
  selectDashboardFGroup,
  selectDashboardPGroup,
  selectDashboardPGroupFile,
  selectDashboardChatbot,
} from "../../lib/features/admin/adminSlice"
import { selectToken, selectStatus } from "../../lib/features/auth/authSlice"


const FileName = ({ node }: { node: TreeNode }) => {
  const [isHovered, setIsHovered] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);

  const handleMouseEnter = () => {
    const textWidth: number = textRef.current?.scrollWidth || 0; // 실제 텍스트 너비
    const containerWidth: number = textRef.current?.offsetWidth || 0; // 컨테이너 너비

    // 텍스트가 컨테이너 너비보다 클 경우에만 애니메이션을 활성화
    if (textWidth > containerWidth) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <span
      ref={textRef}
      style={{
        display: "inline-block",
        maxWidth: "800px", // 고정 너비
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        position: "relative",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      title={node?.fgroup_id ? node.fgroup_name : node.pdf_name}
    >
      <span
        // style={{
        //   display: "inline-block",
        //   transform: isHovered ? `translateX(-${(textRef.current?.scrollWidth||0) - (textRef.current?.offsetWidth||0)}px)` : "translateX(0)",
        //   transition: isHovered ? "transform 3s linear" : "transform 0s",
        // }}
      >
        {node?.fgroup_id ? node.fgroup_name : node.pdf_name}
      </span>
    </span>
  );
};




type TreeNode = {
  fgroup_id?: string
  fgroup_name?: string
  pdf_id?: string
  pdf_name?: string
  isFolder: boolean
  child?: TreeNode[]
}

type TreeNodeProps = {
  node: TreeNode
  level: number
  onSelect: (node: TreeNode) => void
  selectedId: string | null
  onFileSelect: (node: TreeNode) => void
}
 
const TreeNode: React.FC<TreeNodeProps> = ({ node, level, selectedId, onSelect, onFileSelect }) => {
  const [isOpen, setIsOpen] = useState(false)
  const hasChildren = node.child && node.child.length > 0
  const isSelected = node.fgroup_id === selectedId

  const handleClick = () => {
    if (node.isFolder) {
      setIsOpen(!isOpen)
      onSelect(node)
    }
    else {
      onFileSelect(node)
    }
  }

  return (
    <div style={{ marginLeft: `${level==0?0:20}px` }}>
      <div
        className={`flex justify-start items-center py-1 px-1 rounded cursor-pointer ${isSelected ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
        onClick={handleClick}
        role="treeitem"
        aria-expanded={hasChildren ? isOpen : undefined}
        aria-selected={isSelected}
        title={node?.fgroup_id ? node.fgroup_name : node.pdf_name}
      >
        {hasChildren ? (
        <span className="w-4 mr-1 focus:outline-none">
          {isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
        </span>
      ) : (
        <span className="mr-3 " />
      )}
      <span className="mr-2 flex-shrink-0">
        {node?.fgroup_id ? (
          <Folder size={16} className="text-blue-500" />
        ) : (
          <File
            size={16} 
            className="ml-1 text-red-500" 
          />
        )}
      </span>
      {/* <span className="truncate flex-grow" title={node?.fgroup_id ? node.fgroup_name : node.pdf_name}>
        {node?.fgroup_id ? node.fgroup_name : node.pdf_name}
      </span> */}
      <FileName node={node} />
    </div>
      {isOpen && hasChildren && (
        <div role="group">
          {(node.child || [])
            .sort((a, b) => (a?.fgroup_id?'D-'+a.fgroup_name:'P-'+a.pdf_name).localeCompare(b?.fgroup_id?'D-'+b.fgroup_name:"P-"+b.pdf_name))
            .map((childNode) => (
            <TreeNode 
              key={childNode.fgroup_id} 
              node={childNode} 
              level={level + 1} 
              selectedId={selectedId}
              onSelect={onSelect}
              onFileSelect={onFileSelect}
            />
          ))}
        </div>
      )}
    </div>
  )
}

type TreeViewProps = {
  data: TreeNode[]
  onSelect: (node: TreeNode) => void
  selectedId: string | null
  onFileSelect: (node: TreeNode) => void
}

export default function TreeView({ data, onSelect, selectedId, onFileSelect }: TreeViewProps) {
  return (
    <div className="text-sm" role="tree">
      {data.map((node) => (
        <TreeNode 
          key={node.fgroup_id} 
          node={node} 
          level={0} 
          onSelect={onSelect}
          onFileSelect={onFileSelect}
          selectedId={selectedId}
        />
      ))}
    </div>
  )
}