// import React from 'react';
// import './sideBarDoc.css';
// // import navModel from '../../data/navItem'
// import {
//   setDocViewer,

//   selectDocViewer,
// } from "../../lib/features/chatMain/chatMainSlice";
// import { useAppDispatch, useAppSelector } from "../../lib/hooks";
// import PDFViewer from '../pdfViewer/PdfViewer';


// function SideBarDoc() {
//   const docViewer = useAppSelector(selectDocViewer);

//   console.log(docViewer)
//   return (
//     <aside id="sidebar-doc" className="sidebar-doc">
//         <ul className="sidebar-doc-panel" id="side-doc-panel">
//             <PDFViewer url={docViewer.docUrl} page={docViewer.docPage}/>
//         </ul>
//     </aside>
//   )
// }

// export default SideBarDoc


// import React from 'react';
// // import './sideBarDoc.css';
// import { useAppSelector } from "../../lib/hooks";
// import PDFViewer from '../pdfViewer/PdfViewer';

// function SideBarDoc() {
//   const docViewer = useAppSelector(state => state.chatMain.docViewer);

//   return (
//     <aside className="sidebar-doc">
//       <PDFViewer url={docViewer?.docUrl} page={docViewer?.docPage} />
//     </aside>
//   );
// }

// export default SideBarDoc;
import React, { useEffect, useState } from 'react';
import { useAppSelector } from "../../lib/hooks";
import PDFViewer from '../pdfViewer/PdfViewer';
import { useSwipeable } from 'react-swipeable';
import { X } from 'lucide-react';

// const SideBarDoc = ({ isOpen }) => {
const SideBarDoc = () => {
  const [isOpen, setIsOpen] = useState(false);
  const docViewer = useAppSelector(state => state.chatMain.docViewer);

  useEffect(() => {
    setIsOpen(docViewer.isOn);
  }, [docViewer]);

  const handleClickToggle = () => {
    setIsOpen(!isOpen);
  };

  const handlers = useSwipeable({
    // onSwipedLeft: () => console.log('Swiped Left!'),
    // onSwipedRight: () => handleClickToggle(),
    
    // 필요에 따라 다른 제스처 이벤트도 추가 가능
  });
  // console.log({isOpen})
  return (
    <div
      // className={`flex flex-row transition-[max-width] mt-[60px] duration-300 ease-in-out bg-gray-200 overflow-hidden z-[2050] ${isOpen ? "max-w-full" : "max-w-0"
      //   }`}
      {...handlers} 
      className={`fixed  bg-white top-0 h-full text-sm ${isOpen?"w-full sm:right-0 !lg:w-[calc(100vw-255px)] lg:w-full ":"w-0 xl:w-0"}  xl:block xl:sticky `}
      // style={{ width: "65%", height: "100%", minWidth: "0px", opacity: "1", willChange: "auto" }}
      // aria-hidden="true"
      inert
    // style={{ width: isOpen ? "100%" : "0" }}
    >
      {/* <div className="group absolute z-10 focus:outline-none inset-y-0 w-3 cursor-col-resize -left-1.5" aria-orientation="vertical" role="separator" tabindex="0">
        <div className="absolute inset-0 transition-all duration-200 group-hover:bg-gray-300 group-focus:bg-gray-400 mx-auto group-hover:w-[3px] group-focus:w-[3px] w-0">
        </div>
      </div> */}
      <div className="absolute inset-0 w-full p-2">
        <div className="w-full h-full border-alpha-200 bg-muted flex flex-col">
          {/* {isOpen ? (
            <div className="self-center w-10 h-[300px] md:h-full border border-gray-500">
              <button
                onClick={() => handleClickToggle()}
                className="w-10 h-[300px] md:h-full bg-gray-100 text-gray-600 hover:bg-gray-200 p-2 rounded absolute left-0.5 z-20"
              >
                &gt;&gt;
              </button>
            </div>
          ) : (
            <div className="self-center w-10 h-[300px] h-full border bg-gray-500 z-20">
              <button
                onClick={() => handleClickToggle()}
                className="w-10 h-[300px] h-full bg-gray-300 text-gray-600 hover:bg-gray-200 p-2 rounded absolute right-0.5"
              >
                &lt;&lt;
              </button>
            </div>
          )} */}

          <div
            className={`flex justify-center items-center p-2 h-[calc(100vh-60px)] mt-[30px] md:h-full md:mt-0 ${isOpen ? "block" : "hidden"}`}
          >
            <div className="flex flex-col w-full h-full">
              <div className="flex justify-between md:flex-col items-center h-[100px]">
                <button
                  className="self-end rounded-full p-1 text-gray-500 hover:bg-gray-200 outline-none focus:ring-2 hover:cursor-pointer hidden md:block"
                  aria-label="Close"
                  onClick={()=>setIsOpen(false)}
                >
                  <X className="h-5 w-5" />
                </button>
                  {/* <span
                  className="self-end hover:cursor-pointer"
                  onClick={()=>setIsOpen(false)}
                  >close x</span> */}
                <h2 className="text-xs text-center text-balance mt-2 mb-2 md:text-lg md:my-0 ">{docViewer?.docTitle}</h2>
                <button
                  className="self-center rounded-full p-1 text-gray-500 hover:bg-gray-200 outline-none focus:ring-2 hover:cursor-pointer block md:hidden"
                  aria-label="Close"
                  onClick={()=>setIsOpen(false)}
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
              <div className="flex flex-col justify-center items-center h-full pb-10 mb-0">
                {docViewer?.docUrl&&<PDFViewer url={docViewer?.docUrl} page={docViewer?.docPage} title={docViewer?.docTitle} isOpen={isOpen} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default SideBarDoc;
