import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import CountrySelect from "react-flags-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { useAppDispatch, useAppSelector } from "../lib/hooks";
import {
  selectChatMain,
  chatMessageGet,
} from "../lib/features/chatMain/chatMainSlice";

import { selectUser, serviceEmailSend } from "../lib/features/auth/authSlice";
import { selectToken } from "../lib/features/auth/authSlice";
import Header from "../components/dashboard/Header";
import ChatSideBar from "../components/main/ChatSideBar";
import { Input } from "../components/ui/input";
import { Button, Card } from "react-bootstrap";
import { ChevronDown, Paperclip, Send, Upload, X } from "lucide-react";
import { useDropzone } from 'react-dropzone'
import { Label } from "../components/ui/label";
import { Textarea } from "../components/ui/textarea";
import ChatHeader from "../components/main/ChatHeader";

const APP_STAGE = process.env.REACT_APP_STAGE;
const MAX_TOTAL_SIZE = 25 * 1024 * 1024;

const EmailSendPage = () => {
  const { roomId } = useParams();
  const chatMain = useAppSelector(selectChatMain);
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const [newEmail, setNewEmail] = useState(""); // 새 이메일을 입력할 상태
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState({
    // yourName: user.user_name,
    imono: user.login_id,
    vesselName: user.vessel_name,
    yourName: user.user_name,
    shipowner: user.Company?.company_name,
    serviceCountry: null,
    serviceDate: new Date(),
    issueDetail: "",
    shipEmail: [user.user_email],
    phoneNumber: user.vessel_contact,
    attachment: null,
  });
  const [errorMessage, setErrorMessage] = useState(""); // 에러 메시지 상태 추가
  const [successMessage, setSuccessMessage] = useState(""); // 성공 메시지 상태 추가
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentTotalSize, setCurrentTotalSize] = useState(0);

  console.log(user)
  useEffect(() => {
    chatMessageGet({ room_id: roomId, token: token, offset: 0 });
  }, [roomId]); // 의존성 배열에 roomId 추가

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCountryChange = (selectedOption) => {
    setFormData({ ...formData, serviceCountry: selectedOption });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, serviceDate: date });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, attachment: e.target.files[0] });
  };
  // 이메일 추가 핸들러
  const handleAddEmail = () => {
    if (newEmail.trim() !== "") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        shipEmail: [...prevFormData.shipEmail, newEmail.trim()],
      }));
      setNewEmail(""); // 입력 필드 초기화
    }
  };

  // 이메일 삭제 핸들러
  const handleRemoveEmail = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      shipEmail: prevFormData.shipEmail.filter((_, i) => i !== index),
    }));
  };

  const [files, setFiles] = useState([])
  const [message, setMessage] = useState('')
  const [isDragging, setIsDragging] = useState(false)
  const [isOpen, setIsOpen] = useState(true)

  const onDrop = useCallback((acceptedFiles) => {
    const currentTotalSize = files.reduce((total, file) => total + file.size, 0);
  
    const newFiles = acceptedFiles.filter(
      (file) =>
        !files.some(
          (existingFile) =>
            existingFile.name === file.name && existingFile.lastModified === file.lastModified
        )
    );

    const newFilesTotalSize = newFiles.reduce((total, file) => total + file.size, 0);
    

    if (currentTotalSize + newFilesTotalSize <= MAX_TOTAL_SIZE) {
      setFiles([...files, ...newFiles]);
      setCurrentTotalSize(currentTotalSize + newFilesTotalSize);
      
    } else {
      setErrorMessage("The total file size cannot exceed 25MB. Please remove some files or select smaller files.");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  
    setIsDragging(false);
  }, [files]);

  if (APP_STAGE === "DEV") {  
    console.log('files', files)
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false)
  })

  const handleRemoveFile = (index) => {
    setFiles(files.filter((_, i) => i !== index))
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    // 유효성 검사
    if (
      !formData.imono ||
      !formData.vesselName ||
      !formData.yourName ||
      !formData.shipowner ||
      // !formData.serviceCountry ||
      !formData.issueDetail ||
      formData.shipEmail.length === 0 ||
      !formData.phoneNumber
    ) {
      setErrorMessage("Please fill in all required fields.");
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    if (currentTotalSize > MAX_TOTAL_SIZE) {
      setErrorMessage("The total file size cannot exceed 25MB. Please remove some files or select smaller files.");
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }

    // 유효성 통과 후 데이터 제출 및 폼 초기화
    if (APP_STAGE === "DEV") {  
      console.log("Form Data:", formData);
      console.log("Room ID:", roomId);
    }
    dispatch(
      serviceEmailSend({ roomId: chatMain.roomId, data: formData, token, files: files })
    ).then(() => {
      // setFormData({
      //   imono: "",
      //   vesselName: "",
      //   yourName: "",
      //   shipowner: "",
      //   serviceCountry: null,
      //   serviceDate: new Date(),
      //   issueDetail: "",
      //   phoneNumber: "",
      //   shipEmail: [],
      //   attachment: files,
      // });
      setErrorMessage(""); // 에러 메시지 초기화
      setSuccessMessage("Successfully sent!"); // 성공 메시지 설정
      setMessage('')
      setFiles([])
    });
  };
  useEffect(() => {
    const messageText = chatMain?.messages?.map(
      msg => (msg.isBot?`\n[[CHATBOT]] ${msg.sendTime}\n`:`\n[[USER]] ${msg.sendTime}\n`)
      +JSON.parse(msg.text).map(msg => msg.text.replace(/\<key>|<\/key>/g, "")).join('\n')
      +(msg.isBot?"\n[[REFERENCE DOCUMENT]] \n"+(
        msg.reference?.length > 0
          ? Array.from(
              new Set(
                msg?.reference?.map((ref) => `[${ref.file_nm}]`)
              )
            ).join("\n") + "\n" 
          : "[None]\n"
      )+"\n":"")
    ).join('\n');
    console.log(messageText, chatMain?.messages)
    
    const defaultFile = new File([messageText], 'Chatbot-Dialogue.txt', { type: 'text/plain' })

    if(files.length === 0) {
      if (APP_STAGE === "DEV") {  
        console.log('defaultFile')
      }
      setFiles([...files, defaultFile])
      setCurrentTotalSize(currentTotalSize + defaultFile.size)
    }
  }, [])



  return (
    <div {...getRootProps()} className="min-h-screen w-full flex justify-center bg-gray-100">
      
      <input {...getInputProps()} />
      {isDragging && (
        <div className="fixed inset-0 bg-blue-500 bg-opacity-50 flex items-center justify-center z-50">
          <div className="text-white text-2xl font-bold">파일을 여기에 놓으세요</div>
        </div>
      )}
      <ChatSideBar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
      <main className="flex flex-col justify-start items-center w-full bg-white p-2 sm:p-6">
        <ChatHeader />
        <aside className="composer-parent flex w-full h-full focus-visible:outline-0">
          <div className="container mx-auto flex flex-col lg:flex-row justify-between max-w-4xl p-2 sm:p-6 bg-white shadow-md rounded-md">
            <form
              onSubmit={successMessage ? () => {} : handleSubmit}
              className="flex-1 mb-8 pb-16 md:mb-0 px-4 overflow-y-auto"
            >
              <h2 className="text-2xl font-semibold text-[#1565c0] mb-4">
                SERVICE REQUEST
              </h2>
              <div className="mb-4">
                <Input
                  label="IMO No."
                  type="text"
                  name="imono"
                  value={formData.imono}
                  onChange={successMessage ? () => {} : handleChange}
                  className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:border-[#1565c0]"
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Vessel Name"
                  type="text"
                  name="vesselName"
                  value={formData.vesselName}
                  onChange={successMessage ? () => {} : handleChange}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Your Name"
                  type="text"
                  name="yourName"
                  value={formData.yourName}
                  onChange={successMessage ? () => {} : handleChange}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <Input
                  label="Ship Owner"
                  type="text"
                  name="shipowner"
                  value={formData.shipowner}
                  onChange={successMessage ? () => {} : handleChange}
                  className="w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                {!successMessage ? (
                  <div className="flex gap-2 items-center relative">
                    <Input
                    label="Ship Email"
                    type="email"
                    value={newEmail}
                    onChange={successMessage ? () => {} : (e) => setNewEmail(e.target.value)}
                    className="flex-1 px-4 py-2 border rounded-l-md text-gray-700 focus:outline-none focus:border-[#1565c0]"
                    placeholder="example@domain.com"
                  />
                  <button
                    type="button"
                    onClick={handleAddEmail}
                    className="absolute right-0 px-4 py-2 mb-1 mr-2 h-12 bg-gradient-to-br from-cyan-500 to-blue-600 hover:from-cyan-600 hover:to-blue-700 text-white text-sm font-semibold rounded-full  focus:outline-none"
                  >
                      ADD
                    </button>
                  </div>
                ): <Label className="text-blue-500 font-normal text-xs ml-2 mb-0">Email List</Label>}
                <div className="mt-2">
                  {formData.shipEmail.map((email, index) => (
                    <div
                      key={index}
                      className="flex flex-wrap items-center justify-between bg-gray-200 p-2 rounded-md mt-1"
                    >
                      <span>{email}</span>
                      <button
                        type="button"
                        onClick={successMessage ? () => {} : () => handleRemoveEmail(index)}
                        className="text-red-500 font-bold text-sm"
                      >
                        {successMessage ? null: <X className="w-4 h-4" />}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mb-4">
                <Input
                  label="Phone Number"
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={successMessage ? () => {} : handleChange}
                  className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:border-[#1565c0]"
                  placeholder="010-0000-0000"
                />
              </div>

              
              <div className="mb-2">
                {/* <Label className="text-blue-500 font-normal text-xs ml-2 mb-0">
                  Issue Detail
                </Label>  */}
                <Textarea
                  label="Issue Detail"
                  name="issueDetail"
                  placeholder="Please describe your issue in detail."
                  value={formData.issueDetail}
                  onChange={successMessage ? () => {} : handleChange}
                  className={`w-full px-4 py-2 border rounded-lg text-gray-700 border border-gray-300 text-gray-900 placeholder-transparent hover:ring-2 hover:ring-blue-100 focus:outline-none focus:border-blue-500 rounded-md px-3 py-4 transition-all duration-200 
                    focus:border-blue-500 focus:ring-2 focus:ring-blue-200`}
                  rows="5"
                />
              </div>
              <div className="mb-4">
                <Label className="text-gray-900 font-semibold text-lg ml-2 mb-0">
                  Attachment <span className="text-xs text-gray-500">({currentTotalSize>1024*1024?`${Math.round(currentTotalSize/1024/1024)} MB`: currentTotalSize>1024?`${Math.round(currentTotalSize/1024)} KB`: `${currentTotalSize} Bytes `} / {MAX_TOTAL_SIZE/1024/1024} MB)</span>
                </Label> 
                
                <Card className="w-full max-w-2xl p-4 border border-gray-300 text-gray-900 placeholder-transparent hover:ring-2 hover:ring-blue-100 focus:outline-none focus:border-blue-500 rounded-md px-3 py-4 transition-all duration-200 
                    focus:border-blue-500 focus:ring-2 focus:ring-blue-200">
                  <form onSubmit={handleSubmit} className="space-y-4">
                    {files.length > 0 ? (
                      <div className="flex flex-wrap gap-2">
                        {files.map((file, index) => (
                          <div key={index} className="flex  items-center bg-gray-200 rounded-full px-3 py-1">
                            <Paperclip className="w-4 h-4 mr-2" />
                            <span className="text-sm truncate max-w-[150px]">{file.name}</span>
                            <Button
                              type="button"
                              variant="ghost"
                              size="icon"
                              className="ml-2 h-5 w-5"
                              onClick={successMessage ? () => {} : () => handleRemoveFile(index)}
                            >
                              <X className="h-3 w-3" />
                            </Button>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center text-gray-500">Attach additional files.</div>
                    )}
                    <div className="flex justify-center">
                      <button
                        type="button"
                        variant="outline"
                        onClick={successMessage? ()=>{} : ()=> document.querySelector('input')?.click()}
                        className="flex text-gray-400 border border-gray-300 rounded-md px-3 py-2"
                      >
                        <Upload className="w-4 h-4 mr-2" />
                        <span className="text-sm">Select File</span>
                      </button>
                    </div>
                  </form>
                </Card>
              </div>
              {(errorMessage||successMessage) && (
                <div 
                  className={`bottom-full left-0 w-full px-4 py-2 mb-2 text-sm text-white ${successMessage ? 'bg-green-500 text-center' : 'bg-red-500'} rounded shadow`}
                >
                  {successMessage ? successMessage : errorMessage}
                </div>
              )}
              <button
                type="submit"
                className="w-full py-2 px-4 bg-gradient-to-br from-cyan-500 to-blue-600 hover:from-cyan-600 hover:to-blue-700 text-white rounded-md transition-colors"
              >
                Request
              </button>
              
            </form>

            <div className="chat-history hidden sm:flex sm:flex-col sm:flex-1 md:pl-6 border-t md:border-t-0 md:border-l border-gray-300 overflow-y-auto overflow-x-hidden ">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                Chat History
              </h3>
              {chatMain?.messages?.length > 0 ? (
                chatMain.messages.map((message, index) => {
                  const text = JSON.parse(message.text).map(msg => msg.text.replace(/\<key>|<\/key>/g, "")).join('\n');
                  // console.log( JSON.parse(message.text));
                  return (
                    <div
                      key={message.id}
                      className={`mb-2 p-4 rounded-lg text-sm ${
                        message.isBot ? "bg-blue-100" : "bg-gray-100"
                      }`}
                    >
                    <p className="font-semibold m-1">
                      {message.isBot ? "Support" : "User"}: {text}
                    </p>
                    {message.reference?.length > 0 && (
                      <div className="mt-1">
                        <strong>Reference:</strong>
                        {message.reference.map((ref, refIndex) => (
                          <div key={ref.reference_id}>
                            <p>Text: {ref.reference_text}</p>
                            <a
                              href={ref.pdf_url}
                              target="_blank"
                              rel="noreferrer"
                              className="text-blue-500 hover:underline"
                            >
                              View PDF (Page {ref.pdf_page})
                            </a>
                          </div>
                        ))}
                      </div>
                    )}
                    </div>
                  );
                })
              ) : (
                <p>No messages yet.</p>
              )}
            </div>
          </div>
        </aside>
        <div className={`absolute bottom-0 w-full bg-white p-2 border-r border-gray-200 shadow-md pb-4 sm:hidden ${isOpen ? 'h-1/2' : 'h-0'} transition-all duration-300`}>
          <div 
            onClick={()=>setIsOpen(!isOpen)}
            className={`flex justify-center items-center border-gray-200 pb-2 mb-2 cursor-pointer ${isOpen ? 'border-b border-t' : 'border-t'} hover:scale-110`}>
            <ChevronDown className={`w-6 h-6 transition-transform duration-300 ${!isOpen ? 'rotate-180' : ''} `} />
          </div>
          <div className={`w-full h-full p-4 overflow-y-auto transition-all duration-300 ${isOpen ? 'max-h-full' : 'max-h-0'}  transition-all duration-300`}>
            {chatMain?.messages?.length > 0 ? (
              chatMain.messages.map((message, index) => {
              const text = JSON.parse(message.text).map(msg => msg.text.replace(/\<key>|<\/key>/g, "")).join('\n');
                  // console.log( JSON.parse(message.text));
                  return (
                    <div
                      key={message.id}
                      className={`mb-2 p-4 rounded-lg text-sm ${
                        message.isBot ? "bg-blue-100" : "bg-gray-100"
                      }`}
                    >
                    <p className="font-semibold m-1">
                      {message.isBot ? "Support" : "User"}: {text}
                    </p>
                    {message.reference?.length > 0 && (
                      <div className="mt-1">
                        <strong>Reference:</strong>
                        {message.reference.map((ref, refIndex) => (
                          <div key={ref.reference_id}>
                            <p>Text: {ref.reference_text}</p>
                            <a
                              href={ref.pdf_url}
                              target="_blank"
                              rel="noreferrer"
                              className="text-blue-500 hover:underline"
                            >
                              View PDF (Page {ref.pdf_page})
                            </a>
                          </div>
                        ))}
                      </div>
                    )}
                    </div>
                  );
                })
              ) : (
                <p>No messages yet.</p>
              )}
          </div>
        </div>
      </main>
    </div>
  );
};

export function FileUpload({files, setFiles}) {
  // const [files, setFiles] = useState([])
  const [message, setMessage] = useState('')
  const [isDragging, setIsDragging] = useState(false)

  const onDrop = useCallback((acceptedFiles) => {
    const newFiles = acceptedFiles.filter(
      (file) => !files.some((existingFile) => existingFile.name === file.name && existingFile.lastModified === file.lastModified)
    );
    setFiles([...files, ...newFiles]);
    setIsDragging(false);
  }, [files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false)
  })

  const handleRemoveFile = (index) => {
    setFiles(files.filter((_, i) => i !== index))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('Sending message:', message, 'with files:', files)
    setMessage('')
    setFiles([])
  }

  return (
    <div {...getRootProps()} className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <input {...getInputProps()} />
      {isDragging && (
        <div className="fixed inset-0 bg-blue-500 bg-opacity-50 flex items-center justify-center z-50">
          <div className="text-white text-2xl font-bold">파일을 여기에 놓으세요</div>
        </div>
      )}
      <Card className="w-full max-w-2xl p-4">
        <form onSubmit={handleSubmit} className="space-y-4">
          {files.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {files.map((file, index) => (
                <div key={index} className="flex items-center bg-gray-200 rounded-full px-3 py-1">
                  <Paperclip className="w-4 h-4 mr-2" />
                  <span className="text-sm truncate max-w-[150px]">{file.name}</span>
                  <Button
                    type="button"
                    variant="ghost"
                    size="icon"
                    className="ml-2 h-5 w-5"
                    onClick={() => handleRemoveFile(index)}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </div>
              ))}
            </div>
          )}
          <div className="flex items-center space-x-2">
            <Input
              type="text"
              placeholder="메시지를 입력하세요..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="flex-grow"
            />
            <Button type="submit" size="icon">
              <Send className="h-4 w-4" />
            </Button>
          </div>
        </form>
      </Card>
    </div>
  )
}

export default EmailSendPage;
