import React, { useEffect, useMemo, useState } from "react";
import * as XLSX from "xlsx";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
} from "../../ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";

import {
  Plus,
  Search,
  Filter,
  EllipsisVertical,
  MoreVertical,
  Check,
  X,
  Trash2,
  UserPlus,
  Users,
  Download,
  Upload,
} from "lucide-react";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import Pagination from "../Common/Pagination";
import {
  adminPicUpload,
  adminPicDelete,
  adminPicList,
  adminVesselAdd,
  adminVesselDelete,
  adminVesselList,
} from "../../../lib/features/admin/adminSlice";

const ITEMS_PER_PAGE = 10;
const APP_STAGE = process.env.REACT_APP_STAGE;

const PersonInCharge = ({
  token
}: {
  token: any;
}) => {
  const dispatch = useAppDispatch();
  const pics = useAppSelector((state) => state.adminPage.dashboard.pics);
  const vessels = useAppSelector((state) => state.adminPage.dashboard.vessels);

  const [currentPage, setCurrentPage] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState({
    upload: false,
    download: false,
    delete: false,
    detail: false,
  });
  const [selectedPicId, setSelectedPicId] = useState<any>(null);

  const [newVesselForm, setNewVesselForm] = useState({
    imo_no: "",   
    ship_name: "",
    name: "",
    name_en: "",
    email: "",
    phone: "",
  });
  const [fileData, setFileData] = useState(null);
  const [parsedData, setParsedData] = useState<any>([]);
  const [fileName, setFileName] = useState("");

  const [detailVesselForm, setDetailVesselForm] = useState({
    imo_no: "",
    ship_name: "",
    name: "",
    name_en: "",
    email: "",
    phone: "",
  });

  const [vesselDataForm, setVesselDataForm] = useState(
    vessels?.map((vessel: any) => ({
      id: vessel.vessel_id,
      imo_no: vessel.imo_no,
      ship_name: vessel.ship_name,
      name: vessel.PIC.pic_name,
      name_en: vessel.PIC.pic_name_en,
      email: vessel.PIC.pic_email,
      phone: vessel.PIC.pic_phone,
      created_at: vessel.created_at,
      updated_at: vessel.updated_at
    })) || []
  );

  const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

  useEffect(() => {
    dispatch(adminPicList({ token }));
    dispatch(adminVesselList({ token }));
  }, []);

  function formatDateTime(dateTimeString: string) {
    if (dateTimeString.length !== 14) {
      return "Invalid input format.";
    }

    const year = dateTimeString.slice(0, 4);
    const month = dateTimeString.slice(4, 6);
    const day = dateTimeString.slice(6, 8);
    const hour = dateTimeString.slice(8, 10);
    const minute = dateTimeString.slice(10, 12);
    const second = dateTimeString.slice(12, 14);

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name); // 파일 이름 저장
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const binaryStr = event.target.result;
          const workbook = XLSX.read(binaryStr, { type: "binary" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet);
          setParsedData(jsonData); // 파싱된 데이터 저장
        } else {
          if (APP_STAGE === "DEV") {  
            console.log("event.target null");
          }
        }
      };
      reader.readAsBinaryString(file); // 파일 읽기
      setFileData(file); // 파일 저장
    }
  };

  const handleUpload = () => {
    if (!fileData) return;

    const formData = new FormData();
    formData.append("files", fileData); // 선택한 파일 추가
    // formData.append("chatbotId", chatbots[0]?.chatbot_id); // chatbotId 추가

    dispatch(
      adminPicUpload({
        token,
        fileData: formData,
        // chatbotId: chatbots[0]?.chatbot_id,
      })
    ).then(() => {
      setFileName("");
      setFileData(null);
      alert("File upload successfully!");
    });
  };

  useEffect(() => {
    if (vessels)
      setVesselDataForm(
        vessels.map((vessel: any) => ({
          id: vessel.vessel_id,
          imo_no: vessel.imo_no,
          ship_name: vessel.ship_name,
          name: vessel.PIC.pic_name,
          name_en: vessel.PIC.pic_name_en,
          email: vessel.PIC.pic_email,
          phone: vessel.PIC.pic_phone,
          created_at: vessel.created_at,
          updated_at: vessel.updated_at,
        })) || []
      );
  }, [vessels]);

  const sortedVesselData = useMemo(() => {
    return [...vesselDataForm].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key])
        return sortConfig.direction === "asc" ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key])
        return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [vesselDataForm, sortConfig]);
  const totalPages = Math.ceil(sortedVesselData.length / ITEMS_PER_PAGE);
  const paginatedVesselData = sortedVesselData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleFileDownload = () => {
    const transformedData = vesselDataForm.map((vessel: any) => ({
      IMO_NO: vessel.imo_no,
      SHIP_NAME: vessel.ship_name,
      NAME: vessel.name,
      NAME_EN: vessel.name_en,
      EMAIL: vessel.email,
      PHONE: vessel.phone,
    }));
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "담당자 기본 정보");
    XLSX.writeFile(workbook, "PIC.xlsx");
  };

  const handleDelete = (pic_id: any) => {
    dispatch(
      adminPicDelete({
        token,
        pic_id,
      })
    ).then(() => {
      alert("Delete completed successfully!");
    });
  };

  const handleSort = (key: string) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        return {
          ...currentConfig,
          direction: currentConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };
  //   console.log({ FAQDataForm });
  //   console.log({ paginatedFAQData });

  const deletePicBtn = (pic_id: any) => {
    setSelectedPicId(pic_id);
    setIsDialogOpen((prev: any) => ({ ...prev, ["delete"]: true }));
  };


  const [searchType, setSearchType] = useState<'imo' | 'ship' | 'name'>('imo');
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      // API 호출 대신 가상 데이터 필터링
      const filteredData = vesselDataForm.filter((vessel: any) => {
        if (searchType === 'imo' && vessel.imo_no) {
          return vessel.imo_no.includes(searchTerm);
        } else if (searchType === 'ship') {
          return vessel.ship_name.toLowerCase().includes(searchTerm.toLowerCase());
        } else if (searchType === 'name') {
          return vessel.pic_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                vessel.pic_name_en.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
      });
      setResults(filteredData);
    } catch (error) {
      console.error('검색 중 오류가 발생했습니다:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden p-4 max-w-screen-xl">
        <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">담당자 검색</h1>
      <div className="flex space-x-2 mb-4">
        <select
          className="border p-2 rounded"
          value={searchType}
          onChange={(e) => setSearchType(e.target.value as 'imo' | 'ship' | 'name')}
        >
          <option value="imo">IMO 번호</option>
          <option value="ship">선박 이름</option>
          <option value="name">담당자 이름</option>
        </select>
        <Input
          id="searchTerm"
          label={`${searchType === 'imo' ? 'IMO 번호' : searchType === 'ship' ? '선박 이름' : '담당자 이름'}을 입력하세요`}
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button onClick={handleSearch} disabled={isLoading}>
          {isLoading ? '검색 중...' : '검색'}
        </Button>
      </div>

      {results.length > 0 ? (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>IMO 번호</TableHead>
              <TableHead>선박 이름</TableHead>
              <TableHead>담당자 이름</TableHead>
              <TableHead>이메일</TableHead>
              <TableHead>전화번호</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {results.map((person) => (
              <TableRow key={person.person_in_charge_id}>
                <TableCell>{person.imo_no || 'N/A'}</TableCell>
                <TableCell>{person.ship_name}</TableCell>
                <TableCell>{`${person.pic_name} (${person.pic_name_en})`}</TableCell>
                <TableCell>{person.pic_email}</TableCell>
                <TableCell>{person.pic_phone}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p>검색 결과가 없습니다.</p>
      )}
    </div>

      <div className="border-b flex justify-between items-center">
        <h2 className="text-2xl font-semibold">PIC Data List</h2>
        <div className="flex items-center gap-2">
          <button
            onClick={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["upload"]: true }))}}
            className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
          >
            <Upload className="h-4 w-4" /> Upload
          </button>
          <button
              onClick={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["download"]: true }))}}
              className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
            >
            <Download className="h-4 w-4" /> Download
          </button>
          <Dialog open={isDialogOpen.upload} onOpenChange={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["upload"]: false }))}}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add New PIC File</DialogTitle>
                <DialogDescription>
                  Please enter the information of the new PIC file.
                </DialogDescription>
              </DialogHeader>

              <div className="grid gap-2 py-2">
                <label
                  htmlFor="file-upload"
                  className="block text-sm font-medium text-gray-700"
                >
                  Upload Excel File
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept=".xlsx, .xls"
                  multiple={false}
                  onChange={handleFileChange} // 파일 선택 시 호출되는 함수
                  className="mt-1 block w-full text-sm text-gray-500
                          file:mr-4 file:py-2 file:px-4
                          file:rounded-md file:border-0
                          file:text-sm file:font-semibold
                          file:bg-blue-50 file:text-blue-700
                          hover:file:bg-blue-100"
                />
                {fileName && <p>Selected File: {fileName}</p>}
              </div>
              <DialogFooter>
                <button
                  type="submit"
                  onClick={() => {
                    handleUpload();
                  }}
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                  Add PIC
                </button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog open={isDialogOpen.download} onOpenChange={() => {setIsDialogOpen((prev: any) => ({ ...prev, ["download"]: false }))}}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Download PIC File</DialogTitle>
                <DialogDescription>
                  Do you want to download the PIC file?
                </DialogDescription>
              </DialogHeader>
              <div className="flex justify-center items-center p-6">
                <div className="text-gray-700 text-center">
                  <p className="mb-4">
                    You can download the current PIC file.
                  </p>
                </div>
              </div>
              <DialogFooter className="flex justify-end space-x-4">
                <button onClick={() => handleFileDownload()}>Download</button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          <Dialog open={isDialogOpen.detail} onOpenChange={() => setIsDialogOpen({ ...isDialogOpen, detail: false })}>
        <DialogContent>
            <DialogHeader>
                <DialogTitle>PIC Detail</DialogTitle>
            </DialogHeader>
            
            <DialogDescription>
                <div className="grid grid-cols-4 grid-rows-auto gap-2 text-base ">

                        {[  {label: "IMO No.", value: detailVesselForm.imo_no}, 
                            {label: "Ship Name", value: detailVesselForm.ship_name}, 
                            {label: "Name", value: detailVesselForm.name}, 
                            {label: "Email", value: detailVesselForm.email}, 
                            {label: "Vessel Name", value: detailVesselForm.ship_name}, 
                            {label: "Contact No.", value: detailVesselForm.phone}].map((item) => (
                            <>
                                <span className="font-semibold col-span-1">{item.label}</span>
                                {
                                    item.label === "Status" ? 
                                    <DropdownMenu>
                                        <DropdownMenuTrigger asChild>
                                        <span 
                                            className={`col-span-3 size-fit ${item.value === "pending" 
                                                ? "bg-yellow-500 text-white rounded-md px-2 py-1 text-sm" 
                                                : item.value === "in progress" 
                                                    ? "bg-blue-500 text-white rounded-md px-2 py-1 text-sm" 
                                                    : item.value === "in trouble" 
                                                        ? "bg-red-500 text-white rounded-md px-2 py-1 text-sm" 
                                                        : "bg-green-500 text-white rounded-md px-2 py-1 text-sm"}`}
                                            onClick={item.label === "Status" ? () => {} : () => {}}
                                        >
                                            {item.value?item.value:"-"}
                                        </span>
                                        </DropdownMenuTrigger>
                                    </DropdownMenu>
                                 : item.label === "Group" ?
                                  <DropdownMenu>
                                  <DropdownMenuTrigger asChild>
                                  <span 
                                      className={`col-span-3 size-fit ${item.value === "pending" 
                                          ? "bg-yellow-500 text-white rounded-md px-2 py-1 text-sm" 
                                          : item.value === "in progress" 
                                              ? "bg-blue-500 text-white rounded-md px-2 py-1 text-sm" 
                                              : item.value === "in trouble" 
                                                  ? "bg-red-500 text-white rounded-md px-2 py-1 text-sm" 
                                                  : "bg-green-500 text-white rounded-md px-2 py-1 text-sm"}`}
                                      onClick={item.label === "Group" ? () => {} : () => {}}
                                  >
                                      {item.value?item.value:"-"}
                                  </span>
                                  </DropdownMenuTrigger>
                                  {/* <DropdownMenuContent>
                                    {groups.map((group: any) => (
                                      <DropdownMenuItem onClick={() => handleUpdateGroup(detailVesselForm.id as number, group.group_id)}>
                                          <span className="bg-yellow-500 text-white rounded-md px-2 py-1 text-sm">{group.group_name}</span>
                                      </DropdownMenuItem>
                                    ))}
                                  </DropdownMenuContent> */}
                              </DropdownMenu>
                                :
                                <span className="col-span-3">
                                    {item.value?item.value:"-"}
                                </span>
                                }
                            </>
                        ))}

                    
                </div>
            </DialogDescription>
            <DialogFooter>
                <button
                  onClick={() => {
                    setIsDialogOpen({ ...isDialogOpen, detail: false })
                  }}
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                  Change Info
                </button>
                <button
                  onClick={() => {
                    setIsDialogOpen({ ...isDialogOpen, detail: false })
                    // handleDialogOpen("chatHistory", detailVesselForm.id, detailVesselForm.company_id, detailVesselForm.group_id)
                  }}
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                  Show Messages
                </button>
            </DialogFooter>
        </DialogContent>
      </Dialog>
        </div>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            {["id", "imo_no", "ship_name", "name", "name_en", "email", "phone"].map((key) => (
              <TableHead
                key={key}
                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort(key)}
              >
                {key === "id"
                  ? "NO."
                  : key === "imo_no"
                  ? "IMO NO."
                  : key === "ship_name"
                  ? "SHIP NAME"
                  : key === "name"
                  ? "NAME"
                  : key === "name_en"
                  ? "NAME EN"
                  : key === "email"
                  ? "EMAIL"
                  : key === "phone"
                  ? "PHONE"
                  : ""}
                {sortConfig.key === key && (
                  <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                )}
              </TableHead>
            ))}
            {/* <TableHead>Action</TableHead> */}
          </TableRow>
        </TableHeader>
        <TableBody>
          {paginatedVesselData &&
            paginatedVesselData.map((vessel) => (
              <TableRow 
                key={vessel.id} 
                onClick={() => {setIsDialogOpen({ ...isDialogOpen, detail: true }); setDetailVesselForm(vessel)}}
              >
                <TableCell>{vessel.id}</TableCell>
                <TableCell className="truncate max-w-xs">{vessel.imo_no}</TableCell>
                <TableCell>{vessel.ship_name}</TableCell>
                <TableCell>{vessel.name}</TableCell>
                <TableCell>{vessel.name_en}</TableCell>
                <TableCell>{vessel.email}</TableCell>
                <TableCell>{vessel.phone}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={vessels ? Math.ceil(vessels?.length / ITEMS_PER_PAGE) : 1}
        totalNumber={vessels?.length}
        listName="PIC Data"
      />
    </div>
  );
};

export default PersonInCharge;
