import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
} from "../../ui/dropdown-menu";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";

import {
  Plus,
  Search,
  Filter,
  EllipsisVertical,
  MoreVertical,
  Check,
  X,
  Trash2,
  UserPlus,
  Users,
  Upload,
  Download,
} from "lucide-react";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import Pagination from "../Common/Pagination";
import {
  adminCompanyChange,
  adminRoomGetUser,
  selectDashboardRoomGet,
  adminGroupChange,
  adminUserLockUpdate,
  adminUserEmailSend,
  adminUserCreateByAdmin,
  adminUserUpdate,
  adminUserDelete,
  adminCompanyAdd,
  selectDashboardChatDetail,
  adminChatDetail,
  adminUserReSendEmail,
  adminUserInviteEmail,
} from "../../../lib/features/admin/adminSlice";

import { ChevronsUpDown } from 'lucide-react'

import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "../../ui/command"
import { Item, Label } from "@radix-ui/react-dropdown-menu";
import Message from "../../main/Message";
import * as XLSX from "xlsx";
// @ts-ignore
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { group } from "console";

const ITEMS_PER_PAGE = 20;
const APP_STAGE = process.env.REACT_APP_STAGE;
const UserTable = ({
  users,
  token,
  groups,
  companies,
  userAllChange,
  companyAllChange,
}: {
  users: any;
  token: any;
  groups: any;
  companies: any;
  userAllChange: any;
  companyAllChange: any;
}) => {
  const [isOpenDialog, setIsOpenDialog] = useState({
    chatHistory: false,
    permission: false,
    company: false,
    chatDetail: false,
    userDetail: false,
    userCreate: false,
    userCreateFromExcel: false,
  });
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  );
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<number>(1);

  const dispatch = useAppDispatch();
  const roomGet = useAppSelector(selectDashboardRoomGet);
  const messageGet = useAppSelector(selectDashboardChatDetail);
  const [isChangeUser, setIsChangeUser] = useState(false);
  const [detailUserForm, setDetailUserForm] = useState<any>({
    id: "",
    loginId: "",
    name: "",
    vesselName: "",
    shipOwner: "",
    shipEmail: "",
    phoneNumber: "",
    company: "",
    company_id: "",
    group: "미구분 사용자",
    group_id: "2",
    usage_daily_num: 0,
    usage_totle_num: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [newUserForm, setNewUserForm] = useState({
    id: "",
    loginId: "",
    name: "",
    vesselName: "",
    shipOwner: "",
    shipEmail: "",
    phoneNumber: "",
    company: "",
    company_id: "",
    group: "미구분 사용자",
    group_id: "2",
    usage_totle_num: 0,
    usage_daily_num: 0,
  });
  const Avatar = () => (
    <div className="w-10 h-10 rounded-full overflow-hidden bg-gray-200 flex justify-center items-center">
      {/* <img src={src} alt={alt} className="w-full h-full object-cover" /> */}
    </div>
  );
  const [userForm, setUserForm] = useState(
    users.map((user: any) => ({
      id: user.user_id,
      loginId: user.login_id,
      name: user.user_name,
      email: user.user_email,
      vesselName: user.vessel_name,
      phoneNumber: user.vessel_contact,
      company: user.Company?.company_name,
      role: user.Group.group_name,
      group_id: user.Group.group_id,
      usage_totle_num: user.Usage.total_num,
      usage_daily_num: user.Usage.daily_num,
      joinDate: formatDateTime(user.created_at),
      lastLogin: user.login_last_at ? formatDateTime(user.login_last_at) : "Never", //formatDateTime(user.last_login),
      status: getStatus(user),
    })) || []
  );

  const [sortConfig, setSortConfig] = useState({ key: "id", direction: "asc" });

  function formatDateTime(dateTimeString: string) {
    if (dateTimeString.length !== 14) {
      return "잘못된 입력 형식입니다.";
    }

    const year = dateTimeString.slice(0, 4);
    const month = dateTimeString.slice(4, 6);
    const day = dateTimeString.slice(6, 8);
    const hour = dateTimeString.slice(8, 10);
    const minute = dateTimeString.slice(10, 12);
    const second = dateTimeString.slice(12, 14);

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }
  // 전체 상태 정리
  // 활성, 잠김(이메일, 승인안됨, 비번오류, 이상감지)
  function getStatus(user: any) {
    if (user.is_deleted) {
      return "탈퇴";
      // } else if (!user.is_verified || !user.has_email_check || user.is_locked) {
    } else if (user.is_locked) {
      return "잠김";
    } else {
      return "활성";
    }
  }

  const handleDialogOpen = (
    type: string,
    userId?: number,
    company_id?: number,
    group_id?: number,
    roomId?: number
  ) => {
    setIsOpenDialog((prev) => ({ ...prev, [type]: true }));
    if (APP_STAGE === "DEV") {
      console.log(userId);
    }
    console.log({type,roomId});
    switch (type) {
      case "chatHistory":
        if(userId) {
          dispatch(adminRoomGetUser({ token, user_id: userId }));
        }
        break;
      case "chatDetail":
        console.log({roomId});
        if(roomId) {
          console.log({roomId});
          dispatch(adminChatDetail({ token, room_id: roomId }));
        }
        break;
      case "companyChange":
        if(userId) {
          setSelectedUserId(userId);
        }
        if(company_id) {
          setSelectedCompanyId(company_id);
        }
      case "permissionChange":
        if(userId) {
          setSelectedUserId(userId);
        }
        if(group_id) {
          setSelectedGroupId(group_id);
        }
      case "lockChange":
        if(userId) {
          setSelectedUserId(userId);
        }
    }
  };

  const handleDialogClose = (type: string) => {
    setIsOpenDialog((prev) => ({ ...prev, [type]: false }));
  };
  const handleCompanyChange = (user_id: number, companyId: number) => {
    if (APP_STAGE === "DEV") {
      console.log(selectedUserId);
    }

    setSelectedCompanyId(companyId);
    dispatch(
      adminCompanyChange({
        token,
        company_id: companyId,
        user_id: selectedUserId,
      })
    ).then(() => {
      userAllChange();
      alert("변경되었습니다.");
    });
    // 선택한 회사를 백엔드로 전송하는 로직을 추가할 수 있습니다.
  };

  const handleAccountLockToggle = () => {
    if (APP_STAGE === "DEV") {
      console.log(selectedUserId);
    }
    dispatch(adminUserLockUpdate({ token, userId: selectedUserId })).then(
      () => {
        userAllChange();
        alert("변경되었습니다.");
      }
    );
    // dispatch(
    //   adminCompanyChange({ token, group_id: groupId, user_id })
    // ).then(() => {
    //   userAllChange();
    //   alert("변경되었습니다.");
    // });
    // 선택한 회사를 백엔드로 전송하는 로직을 추가할 수 있습니다.
  };

  const handleGroupChange = (user_id: number, groupId: number) => {
    if (APP_STAGE === "DEV") {
      console.log(selectedUserId);
    }
    setSelectedGroupId(groupId);
    dispatch(
      adminGroupChange({ token, group_id: groupId, user_id: selectedUserId })
    ).then(() => {
      userAllChange();
      alert("변경되었습니다.");
    });
    // dispatch(
    //   adminCompanyChange({ token, group_id: groupId, user_id })
    // ).then(() => {
    //   userAllChange();
    //   alert("변경되었습니다.");
    // });
    // 선택한 회사를 백엔드로 전송하는 로직을 추가할 수 있습니다.
  };

  useEffect(() => {
    setUserForm(
      [...users].map((user: any) => ({
        id: user.user_id,
        loginId: user.login_id,
        name: user.user_name,
        vesselName: user.vessel_name,
        phoneNumber: user.vessel_contact,
        email: user.user_email,
        company: user.Company?.company_name,
        company_id: user.Company?.company_id,
        group_id: user.Group?.group_id,
        role: user.Group?.group_name,
        usage_totle_num: user.Usage.total_num,
        usage_daily_num: user.Usage.daily_num,
        joinDate: formatDateTime(user.created_at),
        lastLogin: user.login_last_at!=="None" ? formatDateTime(user.login_last_at) : "Never", //formatDateTime(user.last_login),
        status: getStatus(user),
      })) || []
    );
  }, [users]);

  const sortedUsers = useMemo(() => {
    return [...userForm].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key])
        return sortConfig.direction === "asc" ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key])
        return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [userForm, sortConfig]);
  const totalPages = Math.ceil(sortedUsers.length / ITEMS_PER_PAGE);
  const paginatedUsers = sortedUsers.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleSort = (key: string) => {
    setSortConfig((currentConfig) => {
      if (currentConfig.key === key) {
        return {
          ...currentConfig,
          direction: currentConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };
  if (APP_STAGE && APP_STAGE in ["DEV", "DEV_LOCAL"]) {
    console.log(userForm[1])
    console.log(companies)
  }

  const handleUpdateGroup = (userId: number, groupId: number) => {
    // UI 업데이트
    setUserForm((prev: any) =>
      prev.map((user: any) =>
        user.id === userId ? { ...user, group_id: groupId, role: groups.find((group: any) => group.group_id === groupId)?.group_name || "Unknown" } : user
      )
    );

    // 상세 사용자 정보도 업데이트
    if (detailUserForm.id === userId) {
      setDetailUserForm((prev: any) => ({
        ...prev,
        group_id: groupId,
        role: groups.find((group: any) => group.group_id === groupId)?.group_name || "Unknown",
      }));
    }

    // 서버에 요청
    dispatch(adminGroupChange({ token, group_id: groupId, user_id: userId })).then(() => {
      userAllChange(); // 서버에서 가져오는 최신 데이터를 동기화
    });
  };

  const handleCreateCompany = (inputValue: string) => {
    setNewUserForm({ ...newUserForm, company: inputValue });
    dispatch(adminCompanyAdd({ token, company: inputValue })).then(() => {
      companyAllChange();
    });
  }

  const handleCreateUser = async () => {

    console.log(newUserForm);


    const company_id = companies.find((company: any) => company.company_name === newUserForm.company)?.company_id;
    const group_id = groups.find((group: any) => group.group_name === newUserForm.group)?.group_id;

    const resultAction = await dispatch(adminUserCreateByAdmin({
        token,
        login_id: newUserForm.loginId,
        email: newUserForm.shipEmail,
        name: newUserForm.name,
        vessel_name: newUserForm.vesselName,
        vessel_contact: newUserForm.phoneNumber,
        company_id: company_id,
        group_id: group_id
      }))
    if(adminUserCreateByAdmin.fulfilled.match(resultAction)) {
      userAllChange();
      alert("생성되었습니다.");
      setIsOpenDialog({ ...isOpenDialog, userCreate: false });
    }else if(adminUserCreateByAdmin.rejected.match(resultAction)) {
      alert("생성에 실패했습니다.");
    }else{
      alert("생성 중 문제가 발생했습니다.");
      console.log(resultAction);
    }
  
  }
  
  const handleSendPWResetEmail = (userId: number) => {
    dispatch(adminUserReSendEmail({ token, userId: userId })).then(() => {
      userAllChange();
      alert("이메일이 전송되었습니다.");
    });
  }
  const handleSendInvitateEmail = (userId: number) => {
    dispatch(adminUserInviteEmail({ token, userId: userId })).then(() => {
      userAllChange();
      alert("이메일이 전송되었습니다.");
    });
  }



  const handleUpdateUser = () => {
    const company_id = companies.find((company: any) => company.company_name === detailUserForm.company)?.company_id;
    const group_id = groups.find((group: any) => group.group_name === detailUserForm.group)?.group_id;

    dispatch(adminUserUpdate({
      token,
      userId: detailUserForm.id,
      groupId: group_id,
      name: detailUserForm.name,
      email: detailUserForm.email,
      vesselName: detailUserForm.vesselName,
      phoneNumber: detailUserForm.phoneNumber,
      companyId: company_id,
    })).then(() => {
      userAllChange();
      alert("변경되었습니다.");
    });
    if (APP_STAGE === "DEV") {
      console.log(detailUserForm);
    }
  }
  const [isLoading, setIsLoading] = useState(false);
  const handleUpdateStatus = async(userId: number, status: string) => {
    if (status !== detailUserForm.status) {
        setIsLoading(true);
        const resultAction = await dispatch(
              adminUserUpdate({
                  token,
                  userId,
                  is_locked: status === "잠김",
              })
          )
          if(adminUserUpdate.fulfilled.match(resultAction)) {
            console.log("update success")
            userAllChange();
            setDetailUserForm((prev: any) => ({
                          ...prev,
                          status,
                      }));
          }else if(adminUserUpdate.rejected.match(resultAction)) {
            alert("변경에 실패했습니다.");
          }else{
            alert("변경 중 문제가 발생했습니다.");
            console.log(resultAction);
          }
            // .then((res: any) => {
            //     if (res.status === 200) {
            //         userAllChange();
            //         setDetailUserForm((prev: any) => ({
            //             ...prev,
            //             status,
            //         }));
            //     } else {
            //         alert("변경에 실패했습니다.");
            //         console.log("변경 실패:", res.status);
            //     }
            // })
            // .catch((err: any) => {
            //     console.error("에러 발생:", err);
            //     alert("요청 처리 중 문제가 발생했습니다.");
            // })
            // .finally(() => {
            //     setIsLoading(false);
            // });
    }
  }
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);

  const handleDeleteUser = (userId: number) => {
    if (isConfirmDelete) {
      dispatch(adminUserDelete({ token, userId: userId })).then(() => {
        userAllChange();
        alert("삭제되었습니다.");
      });
      setIsConfirmDelete(false);
      setIsOpenDialog({ ...isOpenDialog, userDetail: false });
    } else {
      setIsConfirmDelete(true);
    }
  }

  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  // const [sortedUploadUsers, setSortedUploadUsers] = useState([]);
  // const [paginatedUploadUsers, setPaginatedUploadUsers] = useState([]);
  const [sortConfigUpload, setSortConfigUpload] = useState({ key: "index", direction: "asc" });
  const [currentPageUpload, setCurrentPageUpload] = useState(1);
  const ITEMS_PER_PAGE_UPLOAD = 10;
  
  const sortedUploadUsers = useMemo(() => {
    return [...parsedData].sort((a, b) => {
      if (a[sortConfigUpload.key] < b[sortConfigUpload.key])
        return sortConfigUpload.direction === "asc" ? -1 : 1;
      if (a[sortConfigUpload.key] > b[sortConfigUpload.key])
        return sortConfigUpload.direction === "asc" ? 1 : -1;
      return 0;
    });
  }, [parsedData, sortConfigUpload]);
  const totalPagesUpload = Math.ceil(sortedUploadUsers.length / ITEMS_PER_PAGE_UPLOAD);
  const paginatedUploadUsers = sortedUploadUsers.slice(
    (currentPageUpload - 1) * ITEMS_PER_PAGE_UPLOAD,
    currentPageUpload * ITEMS_PER_PAGE_UPLOAD
  );

  const handleSortUpload = (key: string) => {
    setSortConfigUpload((currentConfig) => {
      if (currentConfig.key === key) {
        return {
          ...currentConfig,
          direction: currentConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };
  if (APP_STAGE && APP_STAGE in ["DEV", "DEV_LOCAL"]) {
    console.log(userForm[1])
    console.log(companies)
  }


  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name); // 파일 이름 저장
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const binaryStr = event.target.result;
          const workbook = XLSX.read(binaryStr, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(firstSheet);
          setParsedData(jsonData.map((item: any) => 
            ({...item, 
              GROUP: (item.GROUP?item.GROUP:groups[1].group_name),
              GROUP_ID: (item.GROUP_ID?item.GROUP_ID:2),
              STATUS: "ready"})) as any); // 파싱된 데이터 저장
          console.log(jsonData.map((item: any) => 
            ({...item, 
              GROUP: (item.GROUP?item.GROUP:groups[1].group_name),
              GROUP_ID: (item.GROUP_ID?item.GROUP_ID:2),
              STATUS: "ready"})) as any);
        } else {
          if (APP_STAGE === "DEV") {  
            console.log("event.target null");
          }
        }
      };
      
      reader.readAsArrayBuffer(file); // 파일 읽기
      setFileData(file); // 파일 저장
    }
  };
  // console.log({parsedData})
  

const handleFileDownload = () => {
  // 데이터 변환
  const transformedUserData = parsedData.map((user: any) => ({
    IMO_NO: user.IMO_NO,
    NAME: user.NAME,
    EMAIL: user.EMAIL,
    VESSEL_NAME: user.VESSEL_NAME,
    VESSEL_CONTACT_NO: user.VESSEL_CONTACT_NO,
    COMPANY: user.COMPANY,
    COMPANY_ID: companies.find((company: any) => company.company_name === user.COMPANY)?.company_id,
    GROUP: user.GROUP,
    GROUP_ID: groups.find((group: any) => group.group_name === user.GROUP)?.group_id,
  }));

  const transformedGroupData = groups.map((group: any) => ({
    GROUP_ID: group.group_id,
    GROUP_NAME: group.group_name,
  }));

  const transformedCompanyData = companies.map((company: any) => ({
    COMPANY_ID: company.company_id,
    COMPANY_NAME: company.company_name,
  }));

  const groupNames = groups.map((group: any) => group.group_name);

  // xlsx-populate로 Excel 파일 생성
  XlsxPopulate.fromBlankAsync()
    .then((workbook: any) => {
      // Users 시트 생성
      const userSheet = workbook.sheet(0);
      userSheet.name("Users");

      // 헤더 추가
      const userHeaders = [
        "IMO_NO",
        "NAME",
        "EMAIL",
        "VESSEL_NAME",
        "VESSEL_CONTACT_NO",
        "COMPANY",
        "COMPANY_ID",
        "GROUP",
        "GROUP_ID",
      ];
      userHeaders.forEach((header, colIndex) => {
        userSheet.cell(1, colIndex + 1).value(header).style("bold", true);
      });

      // 사용자 데이터 추가
      // transformedUserData.forEach((user, rowIndex) => {
      //   Object.values(user).forEach((value, colIndex) => {
      //     userSheet.cell(rowIndex + 2, colIndex + 1).value(value || "");
      //   });
      // });

      // Groups 시트 생성
      const groupSheet = workbook.addSheet("Groups");
      groupSheet.cell("A1").value("GROUP_ID").style("bold", true);
      groupSheet.cell("B1").value("GROUP_NAME").style("bold", true);
      transformedGroupData.forEach((group: any, rowIndex: number) => {
        groupSheet.cell(`A${rowIndex + 2}`).value(group.GROUP_ID);
        groupSheet.cell(`B${rowIndex + 2}`).value(group.GROUP_NAME);
      });

      // Companies 시트 생성
      const companySheet = workbook.addSheet("Companies");
      companySheet.cell("A1").value("COMPANY_ID").style("bold", true);
      companySheet.cell("B1").value("COMPANY_NAME").style("bold", true);
      transformedCompanyData.forEach((company: any, rowIndex: number) => {
        companySheet.cell(`A${rowIndex + 2}`).value(company.COMPANY_ID);
        companySheet.cell(`B${rowIndex + 2}`).value(company.COMPANY_NAME);
      });

      // 드롭다운 추가 (Users 시트의 GROUP 열)
      const startRow = 2; // 데이터 시작 행
      const endRow = transformedUserData.length + 1; // 데이터 끝 행
      for (let i = startRow; i <= endRow; i++) {
        userSheet.dataValidation(`H${i}`, {
          type: "list",
          allowBlank: true,
          formula1: `"${groupNames.join(",")}"`, // 드롭다운 값
          showErrorMessage: true,
          errorTitle: "Invalid Selection",
          error: "Please select a valid group from the dropdown.",
        });
      }

      // Excel 파일 출력
      return workbook.outputAsync();
    })
    .then((blob: any) => {
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Users_Create_Template.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((err: any) => {
      console.error("Error generating Excel file:", err);
    });
};
  
  const handleCreateFromExcel = async () => {
    for (let index = 0; index < parsedData.length; index++) {
      const user = parsedData[index] as any;
      const company_id = companies.find((company: any) => company.company_name === user.COMPANY)?.company_id;
      const group_id = groups.find((group: any) => group.group_name === user.GROUP)?.group_id;

      console.log({group_id, user, groups})

      const resultAction = await dispatch(adminUserCreateByAdmin({
          token,
          login_id: user.IMO_NO.toString(),
          email: user.EMAIL,
          name: user.NAME,
          vessel_name: user?.VESSEL_NAME || "",
          vessel_contact: user?.VESSEL_CONTACT_NO?.toString() || "",
          company_id: company_id,
          group_id: group_id
        }))
      if(adminUserCreateByAdmin.fulfilled.match(resultAction)) {
        userAllChange();
        // parsedData = parsedData.filter((item: any) => item.IMO_NO !== user.IMO_NO);
        setParsedData((prev: any) => prev.map((item: any, idx: number) => 
          idx === index
            ? { ...item, STATUS: "created"} 
            : item
        ));
        console.log("생성되었습니다.");
        setIsOpenDialog({ ...isOpenDialog, userCreate: false });
      }else if(adminUserCreateByAdmin.rejected.match(resultAction)) {
        console.log("생성에 실패했습니다.");
        setParsedData((prev: any) => prev.map((item: any, idx: number) => 
          idx === index
            ? { ...item, STATUS: `failed (${resultAction.error.message=="Already exist ID!"?"exist":"unknown"})`} 
            : item
        ));
      }else{
        console.log("생성 중 문제가 발생했습니다.");
        console.log(resultAction);
      }
    }
  }

  const handleUpload = () => {
    if (!fileData){
      alert("Please select the file.");
      return;
    }

    const formData = new FormData();
    formData.append("files", fileData); // 선택한 파일 추가
    // formData.append("chatbotId", chatbots[0]?.chatbot_id); // chatbotId 추가

    // dispatch(
    //   adminFileUpload({
    //     token,
    //     fileData: formData,
    //   })
    // ).then(() => {
    //   setFileName("");
    //   setFileData(null);
    //   adminFaqListChange();
    //   alert("File upload successfully!");

    // });
  };

  // useEffect(() => {
  //   if (faqdatas)
  //     setFAQDataForm(
  //       faqdatas.map((faq: any) => ({
  //         //   id: faq.faq_id,
  //         //   question: faq.question,
  //         //   manual: faq.manual,
  //         //   page: faq.page,
  //         //   answer: faq.answer,
  //         id: faq.faq_id,
  //         question: faq.faq_question,
  //         manual: faq.faq_manual || "-",
  //         page: faq.faq_manual?faq.faq_page:'-',
  //         answer: faq.faq_answer,
  //         type: faq.faq_type=="BWMS-TS"?"troubleshooting":faq.faq_type=="BWMS-MT"?"maintenance":"General inquiry",
  //         lang: faq.faq_lang,
  //         is_reflected: faq.is_reflected,

  //       })) || []
  //     );
  // }, [faqdatas]);


  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden p-4">
      <div className="border-b flex justify-between items-center">
        <h2 className="text-2xl font-semibold">USERS</h2>
        <div className="flex items-center gap-2">
          <button
            onClick={() => {
              handleFileDownload();
            }}
            className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
          >
            <Download className="h-4 w-4" /> Download Template
          </button>
          <button
            onClick={() => {
              setFileName("");
              setFileData(null);
              setParsedData([]);
              setIsOpenDialog({ ...isOpenDialog, userCreateFromExcel: true });
            }}
            className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
          >
            <Upload className="h-4 w-4" /> Create from Excel
        </button>
        <button
          onClick={() => {
            setIsOpenDialog({ ...isOpenDialog, userCreate: true });
          }}
          className="flex items-center gap-2 px-4 py-2 mb-2 bg-blue-500 text-sm text-white rounded-full bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
        >
          <Plus className="h-4 w-4" /> Create User
          </button>
        </div>
        <Dialog 
          open={isOpenDialog.userCreateFromExcel} 
          onOpenChange={() => setIsOpenDialog({ ...isOpenDialog, userCreateFromExcel: false })}>
          <DialogContent className="max-w-[1200px] min-w-[1200px] max-h-[80vh] overflow-y-auto overflow-x-auto">
            <DialogHeader>
              <DialogTitle>Create User from Excel</DialogTitle>
              <DialogDescription>
                Please select the Excel file to create users.
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-2 py-0">
              
                <label
                  htmlFor="file-upload"
                  className="block text-sm font-medium text-gray-700"
                >
                  Upload Excel File
                </label>
                
                {/* 파일 선택 */}
                <input
                  id="file-upload"
                  type="file"
                  accept=".xlsx, .xls"
                  multiple={false}
                  onChange={handleFileChange} // 파일 선택 시 호출되는 함수
                  className="mt-1 block w-full text-sm text-gray-500
                          file:mr-4 file:py-2 file:px-4
                          file:rounded-md file:border-0
                          file:text-sm file:font-semibold
                          file:bg-blue-50 file:text-blue-700
                          hover:file:bg-blue-100"
                />
              {fileName && <p>Selected File: {fileName}</p>}
            </div> 
            
            <Table>
              <TableHeader>
                <TableRow>
                  {[
                    // "index",
                    "IMO_NO",
                    "NAME",
                    "EMAIL",
                    "VESSEL_NAME",
                    "VESSEL_CONTACT_NO",
                    "COMPANY",
                    "GROUP",
                    "STATUS",
                  ].map((key) => (
                    <TableHead
                      key={key}
                      className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleSort(key)}
                    >
                      {key === "index"
                        ? "No."
                        : key === "IMO_NO"
                          ? "IMO No."
                          : key === "NAME"
                            ? "Name"
                            : key === "EMAIL"
                              ? "Email"
                              : key === "VESSEL_NAME"
                                ? "Vessel Name"
                                : key === "VESSEL_CONTACT_NO"
                                  ? "Contact No."
                                  : key === "COMPANY"
                                    ? "Company"
                                  : key === "GROUP"
                                    ? "Group"
                                      : "Status"}
                      {sortConfig.key === key && (
                        <span className="ml-1">
                          {sortConfig.direction === "asc" ? "▲" : "▼"}
                        </span>
                      )}
                    </TableHead>
                  ))}
                  {/* <TableHead>Action</TableHead> */}
                </TableRow>
              </TableHeader>
              <TableBody>
                {paginatedUploadUsers &&
                  paginatedUploadUsers.map((user: any, index: number) => (
                    <TableRow
                      key={index}
                      onClick={() => {}}
                    >

                      {/* <TableCell>{index + 1}</TableCell> */}
                      <TableCell>{user.IMO_NO}</TableCell>
                      <TableCell>{user.NAME}</TableCell>
                      <TableCell>{user.EMAIL}</TableCell>
                      <TableCell>{user.VESSEL_NAME}</TableCell>
                      <TableCell>{user.VESSEL_CONTACT_NO}</TableCell>
                      <TableCell>{user.COMPANY}</TableCell>
                      <TableCell>{user.GROUP}</TableCell>
                      <TableCell>{user.STATUS}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Pagination
              currentPage={currentPageUpload}
              setCurrentPage={setCurrentPageUpload}
              totalPages={parsedData ? Math.ceil(parsedData?.length / ITEMS_PER_PAGE_UPLOAD) : 1}
              totalNumber={parsedData?.length}
              listName="User"
            />
            <DialogFooter>
                <button
                  onClick={() => {
                    handleCreateFromExcel()
                  }}
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                  Create User
                </button>
              </DialogFooter>
          </DialogContent>
        </Dialog>

        <Dialog open={isOpenDialog.userCreate} onOpenChange={() => setIsOpenDialog({ ...isOpenDialog, userCreate: false })}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Create User</DialogTitle>
              <DialogDescription>
                Please enter the information of the new user.
              </DialogDescription>
            </DialogHeader>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.currentTarget);
                // addGroup(formData.get('name'), formData.get('description'))
              }}
            >
              <div className="grid gap-2 py-2">
                <div className="flex items-center gap-4">
                  <Input
                    id="loginId"
                    label="IMO No."
                    comment="IMO No. is used as the login ID, and one primary account can be created per vessel."
                    type="number"
                    value={newUserForm.loginId}
                    placeholder="Enter your IMO No."
                    onChange={(ev) => setNewUserForm({ ...newUserForm, loginId: ev.target.value })}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>
                <div className="flex items-center gap-4">
                  {companies && <SearchComponent
                    label="Company"
                    initialValue=""
                    searchList={companies.map((company: any) => {
                      return {
                        label: company.company_id,
                        value: company.company_name,
                      }
                    })}
                    createItem={handleCreateCompany}
                    setSelectedValue={(val) => setNewUserForm({ ...newUserForm, company: val })}
                  />}
                </div>
                <div className="flex items-center gap-4">
                  {groups && <SearchComponent
                    label="Group"
                    initialValue="미구분 사용자"
                    searchList={groups.map((group: any) => {
                      return {
                        label: group.group_id,
                        value: group.group_name,
                      }
                    })}
                    noAdd={true}
                    setSelectedValue={(val) => setNewUserForm({ ...newUserForm, group: val })}
                  />}
                </div>
                <div className="flex items-center gap-4">
                  <Input
                    id="yourName"
                    label="Your Name"
                    type="text"
                    value={newUserForm.name}
                    placeholder="Enter your name"
                    onChange={(ev) => setNewUserForm({ ...newUserForm, name: ev.target.value })}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>

                <div className="flex items-center gap-4">
                  <Input
                    id="email"
                    label="General Email"
                    type="email"
                    value={newUserForm.shipEmail}
                    placeholder="example@domain.com"
                    onChange={(ev) => setNewUserForm({ ...newUserForm, shipEmail: ev.target.value })}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>

                <div className="flex items-center gap-4">
                  <Input
                    id="vesselName"
                    label="Vessel Name"
                    type="text"
                    value={newUserForm.vesselName}
                    placeholder="Enter your vessel name"
                    onChange={(ev) => setNewUserForm({ ...newUserForm, vesselName: ev.target.value })}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>

                <div className="flex items-center gap-4">
                  <Input
                    id="phoneNumber"
                    label="Contact No."
                    type="text"
                    value={newUserForm.phoneNumber}
                    placeholder="Enter your contact number"
                    onChange={(ev) => setNewUserForm({ ...newUserForm, phoneNumber: ev.target.value })}
                    className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                    isNoSpinner={true}
                  />
                </div>



              </div>
              <DialogFooter>
                <button
                  type="submit"
                  onClick={() => {
                    handleCreateUser()
                  }}
                  className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                >
                  Create User
                </button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog>
        <Dialog 
          open={isOpenDialog.userDetail} 
          onOpenChange={() => {
            setIsLoading(false);
            setIsConfirmDelete(false);
            setIsOpenDialog({ ...isOpenDialog, userDetail: false })
          }}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>User Detail</DialogTitle>
            </DialogHeader>

            {!isChangeUser ?
              <DialogDescription>
                <div className="grid grid-cols-4 grid-rows-auto gap-2 text-base ">

                  {[{ label: "IMO No.", value: detailUserForm.loginId },
                  { label: "Your Name", value: detailUserForm.name },
                  { label: "Company", value: detailUserForm.company },
                  { label: "General Email", value: detailUserForm.email },
                  { label: "Vessel Name", value: detailUserForm.vesselName },
                  { label: "Contact No.", value: detailUserForm.phoneNumber },
                  { label: "Group", value: detailUserForm.role },
                  { label: "Status", value: detailUserForm.status },
                  { label: "Usage", value: [detailUserForm.usage_totle_num, detailUserForm.usage_daily_num] },
                  { label: "Join Date", value: detailUserForm.joinDate },
                  { label: "Last Login", value: detailUserForm.lastLogin }].map((item) => (
                    <>
                      <span className="font-semibold col-span-1">{item.label}</span>
                      {
                        item.label === "Status" ?
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <span
                                className={`col-span-3 size-fit ${item.value === "pending"
                                  ? "bg-yellow-500 text-white rounded-md px-2 py-1 text-sm"
                                  : item.value === "in progress"
                                    ? "bg-blue-500 text-white rounded-md px-2 py-1 text-sm"
                                    : item.value === "in trouble"
                                      ? "bg-red-500 text-white rounded-md px-2 py-1 text-sm"
                                      : "bg-green-500 text-white rounded-md px-2 py-1 text-sm"}`}
                                onClick={item.label === "Status" ? () => { } : () => { }}
                              >
                                {item.value ? item.value : "-"}
                              </span>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                              <DropdownMenuItem onClick={() => handleUpdateStatus(detailUserForm.id as number, "잠김")}>
                                <span className="bg-yellow-500 text-white rounded-md px-2 py-1 text-sm">잠김</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem onClick={() => handleUpdateStatus(detailUserForm.id as number, "활성")}>
                                <span className="bg-blue-500 text-white rounded-md px-2 py-1 text-sm">활성</span>
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                          : item.label === "Group" ?
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <span
                                  className={`col-span-3 size-fit ${item.value === "pending"
                                    ? "bg-yellow-500 text-white rounded-md px-2 py-1 text-sm"
                                    : item.value === "in progress"
                                      ? "bg-blue-500 text-white rounded-md px-2 py-1 text-sm"
                                      : item.value === "in trouble"
                                        ? "bg-red-500 text-white rounded-md px-2 py-1 text-sm"
                                        : "bg-green-500 text-white rounded-md px-2 py-1 text-sm"}`}
                                  onClick={item.label === "Group" ? () => { } : () => { }}
                                >
                                  {item.value ? item.value : "-"}
                                </span>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent>
                                {groups.map((group: any) => (
                                  <DropdownMenuItem onClick={() => handleUpdateGroup(detailUserForm.id as number, group.group_id)}>
                                    <span className="bg-yellow-500 text-white rounded-md px-2 py-1 text-sm">{group.group_name}</span>
                                  </DropdownMenuItem>
                                ))}
                              </DropdownMenuContent>
                            </DropdownMenu>
                            : item.label === "Usage" ?
                            <span className="col-span-3">
                              {item.value ? 
                                <div className="flex gap-4">
                                  <span>{"total. " + item.value[0] + "건"}</span> 
                                  <span>{"daily. " + item.value[1] + "건"}</span>
                                </div>
                                : "-"}
                            </span>
                            :
                            <span className="col-span-3">
                              {item.value ? item.value : "-"}
                            </span>
                      }
                    </>
                  ))}


                </div>
              </DialogDescription>
              :
              <DialogDescription>
                <div className="grid grid-cols-4 grid-rows-auto gap-2 text-base ">

                  {[{ label: "IMO No.", value: detailUserForm.loginId },
                  { label: "Your Name", value: detailUserForm.name },
                  { label: "Company", value: detailUserForm.company },
                  { label: "General Email", value: detailUserForm.email },
                  { label: "Vessel Name", value: detailUserForm.vesselName },
                  { label: "Contact No.", value: detailUserForm.phoneNumber },
                  { label: "Group", value: detailUserForm.role },
                  { label: "Status", value: detailUserForm.status },
                  { label: "Join Date", value: detailUserForm.joinDate },
                  { label: "Last Login", value: detailUserForm.lastLogin }].map((item) => (
                    <>
                      <span className="font-semibold col-span-1">{item.label}</span>
                      {
                        item.label === "Status" &&
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <span
                              className={`col-span-3 size-fit ${item.value === "pending"
                                ? "bg-yellow-500 text-white rounded-md px-2 py-1 text-sm"
                                : item.value === "in progress"
                                  ? "bg-blue-500 text-white rounded-md px-2 py-1 text-sm"
                                  : item.value === "in trouble"
                                    ? "bg-red-500 text-white rounded-md px-2 py-1 text-sm"
                                    : "bg-green-500 text-white rounded-md px-2 py-1 text-sm"}`}
                              onClick={item.label === "Status" ? () => { } : () => { }}
                            >
                              {item.value ? item.value : "-"}
                            </span>
                          </DropdownMenuTrigger>
                        </DropdownMenu>
                      }
                      {item.label === "Group" &&
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <span
                              className={`col-span-3 size-fit ${item.value === "pending"
                                ? "bg-yellow-500 text-white rounded-md px-2 py-1 text-sm"
                                : item.value === "in progress"
                                  ? "bg-blue-500 text-white rounded-md px-2 py-1 text-sm"
                                  : item.value === "in trouble"
                                    ? "bg-red-500 text-white rounded-md px-2 py-1 text-sm"
                                    : "bg-green-500 text-white rounded-md px-2 py-1 text-sm"}`}
                              onClick={item.label === "Group" ? () => { } : () => { }}
                            >
                              {item.value ? item.value : "-"}
                            </span>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            {groups.map((group: any) => (
                              <DropdownMenuItem onClick={() => handleUpdateGroup(detailUserForm.id as number, group.group_id)}>
                                <span className="bg-yellow-500 text-white rounded-md px-2 py-1 text-sm">{group.group_name}</span>
                              </DropdownMenuItem>
                            ))}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      }
                      {item.label === "Your Name" && <div className="col-span-3"><Input
                        id="yourName"
                        label=""
                        type="text"
                        value={detailUserForm.name}
                        placeholder="Enter your name"
                        onChange={(ev) => setDetailUserForm({ ...detailUserForm, name: ev.target.value })}
                        className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        isNoSpinner={true}
                      /></div>}
                      {item.label === "Company" && <div className="col-span-3">{companies && <SearchComponent
                        label=""
                        initialValue=""
                        searchList={companies.map((company: any) => {
                          return {
                            label: company.company_id,
                            value: company.company_name,
                          }
                        })}
                        setSelectedValue={(val) => setDetailUserForm({ ...detailUserForm, company: val })}
                      />}</div>}
                      {item.label === "General Email" && <div className="col-span-3"><Input
                        id="email"
                        label=""
                        type="email"
                        value={detailUserForm.email}
                        placeholder="example@domain.com"
                        onChange={(ev) => setDetailUserForm({ ...detailUserForm, email: ev.target.value })}
                        className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        isNoSpinner={true}
                      /></div>}
                      {item.label === "Vessel Name" && <div className="col-span-3"><Input
                        id="vesselName"
                        label=""
                        type="text"
                        value={detailUserForm.vesselName}
                        placeholder="Enter your vessel name"
                        onChange={(ev) => setDetailUserForm({ ...detailUserForm, vesselName: ev.target.value })}
                        className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        isNoSpinner={true}
                      /></div>}
                      {item.label === "Contact No." && <div className="col-span-3"><Input
                        id="phoneNumber"
                        label=""
                        type="text"
                        value={detailUserForm.phoneNumber}
                        placeholder="Enter your contact number"
                        onChange={(ev) => setDetailUserForm({ ...detailUserForm, phoneNumber: ev.target.value })}
                        className="inputBox w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                        isNoSpinner={true}
                      /></div>}
                      {(item.label == "IMO No." || item.label === "Join Date" || item.label === "Last Login") &&
                        <span className="col-span-3">
                          {item.value ? item.value : "-"}
                        </span>
                      }
                    </>
                  ))}


                </div>
              </DialogDescription>
            }

            <DialogFooter>
              {isConfirmDelete && <Label>Are you sure you want to delete this user? one more time click to delete</Label>}
              <button 
                onClick={() => {
                  if (detailUserForm.status === "활성") {
                    alert("활성 상태의 유저는 삭제할 수 없습니다.");
                    return;
                  }
                  handleDeleteUser(detailUserForm.id as number)
                }}
                className="flex items-center gap-2 px-4 py-2 mb-0 bg-red-500 text-sm text-white rounded-md bg-gradient-to-br from-red-600 to-red-600 hover:bg-red-600 transition-colors"
              >
                Delete User
              </button>
              <button
                onClick={() => {
                  // setIsOpenDialog({ ...isOpenDialog, userDetail: false })
                  setIsChangeUser(!isChangeUser);
                  if (isChangeUser) {
                    handleUpdateUser();
                  }
                  // console.log(item)
                }}
                className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
              >
                {isChangeUser ? "Save" : "Change Info"}
              </button>
              <button
                onClick={() => {
                  setIsOpenDialog({ ...isOpenDialog, chatHistory: true })
                  handleDialogOpen("chatHistory", detailUserForm.id, detailUserForm.company_id, detailUserForm.group_id)
                }}
                className="flex items-center gap-2 px-4 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
              >
                Show Messages
              </button>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <button
                    className="flex items-center gap-2 px-2 py-2 mb-0 bg-blue-500 text-sm text-white rounded-md bg-gradient-to-br from-cyan-600 to-blue-600 hover:bg-blue-600 transition-colors"
                  >
                    <EllipsisVertical className="w-4 h-4" />
                  </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuItem>
                      <span 
                        className="text-xs"
                        onClick={() => handleSendPWResetEmail(detailUserForm.id as number)}
                      >Send Email for Password Reset</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <span 
                        className="text-xs"
                        onClick={() => handleSendInvitateEmail(detailUserForm.id as number)}
                      >Send Invitation Email</span>
                    </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <Dialog
          open={isOpenDialog.chatHistory}
          onOpenChange={() => setIsOpenDialog({ ...isOpenDialog, chatHistory: false })}
        >
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Chat History</DialogTitle>
            </DialogHeader>
            <div className="space-y-4 max-h-[500px] overflow-y-scroll p-10">
              {roomGet &&
                roomGet[0].chatList.slice(0, 50).map((item: any, idx: number) =>(
                  <div 
                    key={item.room_id} 
                    onClick={() => {
                      setIsOpenDialog({ ...isOpenDialog, chatDetail: true })
                      handleDialogOpen("chatDetail", undefined, undefined, undefined, item.room_id)
                    }}
                    className="border-gray-300 border-l-4 border-l-[#1565c0] border-b border-t border-r  p-4 py-2 bg-gray-50 rounded-r-lg rounded flex items-start space-x-4 hover:cursor-pointer hover:bg-[#f5f5f5]">
                    <div className="flex-1 min-w-0 ">
                      <div className="flex items-center justify-start gap-4 mb-1">
                        <span className="text-xs font-bold text-[#1565c0]">{item.cgroup_type=="BWMS-TS"?"BWMS Troubleshooting":item.cgroup_type=="BWMS-MT"?"BWMS Maintenance":item.cgroup_type=="BWMS-GI"?"BWMS General Inquiry":"BWMS Unknown"}</span>
                        <span className="text-xs font-medium text-gray-500">
                          {item.created_at}
                        </span>
                      </div>
                      <p className="text-sm text-gray-700 break-words mb-0">{item.room_name}</p>
                    </div>
                  </div>
                ))}
            </div>
            <DialogFooter>
              <Button
                onClick={() => handleDialogClose("chatHistory")}
              >
                Close
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <Dialog
          open={isOpenDialog.chatDetail}
          onOpenChange={() => setIsOpenDialog({ ...isOpenDialog, chatDetail: false })}
        >
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Chat History</DialogTitle>
            </DialogHeader>
            <div className="space-y-4 max-h-[500px] overflow-y-scroll p-10">
              <div className="group/conversation-turn relative flex w-full min-w-0 flex-col agent-turn">
                <div className="chatMargin"></div>
                {messageGet &&
                  messageGet.messages.map((item: any, index: number) => (
                    <Message key={index} model={item} roomId={messageGet.room_id} messages={messageGet.messages}/>
                  ))}

                {/* {mode=="greet" && greet_message &&
                    greet_message.map((item, index) => (
                    <Message key={index} model={item} />
                  ))} */}
                {/* {streamStatus!=="idle" && <Message key={'waiting'} model={{type:"waiting", isBot:true}} />} */}
              

                <div className="chatFootMargin" />
              </div>
            </div>
            <DialogFooter>
              <Button
                onClick={() => handleDialogClose("chatDetail")}
              >
                Close
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            {[
              "id",
              "loginId",
              "name",
              "email",
              "vesselName",
              "company",
              "role",
              "usage",
              "lastLogin",
              "status",
            ].map((key) => (
              <TableHead
                key={key}
                className="px-6 py-2 text-left text-xs text-nowrap font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort(key)}
              >
                {key === "id"
                  ? "No."
                  : key === "loginId"
                    ? "Login ID"
                    : key === "name"
                      ? "Name"
                      : key === "email"
                        ? "Email"
                        : key === "vesselName"
                          ? "Vessel Name"
                          : key === "company"
                            ? "Company"
                          : key === "role"
                            ? "Group"
                            : key === "usage"
                              ? "Usage"
                              : key === "joinDate"
                                ? "Join Date"
                                : key === "lastLogin"
                                  ? "Last Login"
                                : "Status"}
                {sortConfig.key === key && (
                  <span className="ml-1">
                    {sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                )}
              </TableHead>
            ))}
            {/* <TableHead>Action</TableHead> */}
          </TableRow>
        </TableHeader>
        <TableBody>
          {paginatedUsers &&
            paginatedUsers.map((user) => (
              <TableRow
                key={user.id}
                onClick={() => {
                  setDetailUserForm(user);
                  setSelectedUserId(user.id);
                  setIsOpenDialog({ ...isOpenDialog, userDetail: true })
                }}
              >

                <TableCell>{user.id}</TableCell>
                <TableCell>{user.loginId}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.vesselName}</TableCell>
                <TableCell>{user.company}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{user.usage_totle_num}</TableCell>
                <TableCell>{user.joinDate}</TableCell>
                <TableCell>
                  <div
                    className={`text-xs font-medium px-2 py-1 rounded-full ${user.status === "활성"
                        ? "text-cyan-400"
                        : user.status === "잠김"
                          ? "text-red-500"
                          : "text-gray-500"
                      }`}
                  >
                    {user.status === "활성"
                      ? "active"
                      : user.status === "잠김"
                        ? "locked"
                        : "deleted"}
                  </div>
                </TableCell>
               
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={users ? Math.ceil(users?.length / 20) : 1}
        totalNumber={users?.length}
        listName="사용자"
      />
    </div>
  );
};

export default UserTable;




interface SearchListItem {
  value: string
  label: string
}


export function SearchComponent({
  label,
  initialValue,
  searchList,
  noAdd,
  createItem,
  setSelectedValue
}: {
  label: string,
  initialValue: string,
  searchList: SearchListItem[],
  noAdd?: boolean,
  createItem?: (inputValue: string) => void,
  setSelectedValue?: (val: string) => void
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [inputValue, setInputValue] = useState(initialValue);
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const listRef = useRef<HTMLDivElement>(null);

  const filteredSearchList = useMemo(() => {
    return searchList.filter((item) =>
      item.value.toLowerCase().includes(inputValue.toLowerCase())
    );
  }, [inputValue, searchList]);

  const handleSelect = (selectedValue: string) => {
    setValue(selectedValue);
    setOpen(false);
    setInputValue("");
    setHighlightIndex(-1);
    setSelectedValue && setSelectedValue(selectedValue);
  };
  const handleSelectCreate = (inputValue: string) => {
    createItem && createItem(inputValue);
    setValue(inputValue);
    setOpen(false);
    setInputValue("");
  }

  const isExactMatch = useMemo(() => {
    return searchList.some((item) => item.value.toLowerCase() === inputValue.toLowerCase());
  }, [inputValue, searchList]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!open) return;

    if (event.key === "ArrowDown") {
      setHighlightIndex((prev) =>
        prev < filteredSearchList.length - 1 ? prev + 1 : 0
      );
      event.preventDefault();
    } else if (event.key === "ArrowUp") {
      setHighlightIndex((prev) =>
        prev > 0 ? prev - 1 : filteredSearchList.length - 1
      );
      event.preventDefault();
    } else if (event.key === "Enter" && highlightIndex >= 0) {
      handleSelect(filteredSearchList[highlightIndex].value);
      event.preventDefault();
    } else if (event.key === "Enter" && highlightIndex === -1 && !isExactMatch) {
      handleSelect(inputValue);
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (highlightIndex >= 0 && listRef.current) {
      const activeItem = listRef.current.children[highlightIndex] as HTMLElement;
      const container = listRef.current;

      if (activeItem) {
        const { offsetTop, offsetHeight } = activeItem;
        const { scrollTop, clientHeight } = container;

        // 항목을 컨���이너 중앙으로 스크롤 조정
        const targetScrollTop = offsetTop - clientHeight / 2 + offsetHeight / 2;

        container.scrollTo({
          top: targetScrollTop,
          behavior: "smooth",
        });
      }
    }
  }, [highlightIndex]);

  return (
    <Popover open={open} onOpenChange={setOpen} className="w-full">
      <PopoverTrigger className="w-full">
        <div
          aria-expanded={open}
          className="flex justify-between items-center gap-4"
        >
          <Input
            id={label.toLowerCase()}
            label={label}
            type="text"
            value={searchList.find((item) => item.value === value)?.value || inputValue}
            placeholder={`Enter your ${label}`}
            onChange={(ev) => {
              setInputValue(ev.target.value);
              setValue(ev.target.value);
              setHighlightIndex(-1);
            }}
            onFocus={() => setOpen(true)}
            onKeyDown={handleKeyDown}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            isNoSpinner={true}
          />
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </div>
      </PopoverTrigger>
      <PopoverContent open={open} className="max-h-[200px] overflow-y-auto p-4" ref={listRef}>
        {!noAdd && !isExactMatch && inputValue.trim() && (
          <div
            onClick={() => handleSelectCreate(inputValue)}
            className="flex items-center hover:bg-gray-100 rounded-md p-2 mt-2 cursor-pointer"
          >
            <span className="text-blue-500">+ Create {inputValue}</span>
          </div>
        )}
        {filteredSearchList.length > 0 ? (
          filteredSearchList.map((item, index) => (
            <div
              key={item.value}
              role="option"
              aria-selected={value === item.value}
              onClick={() => handleSelect(item.value)}
              className={`flex items-center hover:bg-gray-100 rounded-md p-2 cursor-pointer ${highlightIndex === index ? "bg-gray-200" : ""
                }`}
            >
              <div>{item.value}</div>
            </div>
          ))
        ) : null}
      </PopoverContent>
    </Popover>
  );
}
