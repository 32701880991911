import { createAppSlice } from "../../createAppSlice";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchAdminUserAllGet,
  fetchAdminGroupListGet,
  fetchAdminGroupAdd,
  fetchAdminUserUpdate,
  fetchAdminFileUpload,
  fetchAdminFaqFinetune,
  fetchAdminGroupDelete,
  fetchAdminGroupUpdate,
  fetchAdminEmailUpdate,
  fetchAdminCompany,
  fetchAdminLockUpdate,
  fetchAdminEmailModeList,
  fetchAdminKeywordDelete,
  fetchAdminModelListGet,
  fetchAdminModelAdd,
  fetchAdminApilogs,
  fetchAdminFaqDelete,
  fetchAdminUserDel,
  fetchAdminFileKeywordUpload,
  fetchAdminModelDelete,
  fetchAdminModelUpdate,
  fetchAdminFileListGetOnfid,
  fetchAdminFileListGet,
  fetchAdminCompanyAdd,
  fetchAdminCompanyChange,
  fetchAdminFileAdd,
  fetchAdminFileUpdate,
  fetchAdminFileDelete,
  fetchAdminFGroupListGet,
  fetchAdminFGroupAdd,
  fetchAdminFGroupDelete,
  fetchAdminFGroupUpdate,
  fetchAdminFGroupAllListGet,
  fetchAdminPGroupListGet,
  fetchAdminPGroupAdd,
  fetchAdminPGroupDelete,
  fetchAdminPGroupUpdate,
  fetchAdminPGroupFileListGet,
  fetchAdminPGroupFileAdd,
  fetchAdminChatbotListGet,
  fetchAdminChatbotAdd,
  fetchAdminChatbotDelete,
  fetchAdminChatbotGroupListGet,
  fetchAdminChatbotGroupAdd,
  fetchAdminChatbotGroupUpdate,
  fetchAdminChatbotGroupDelete,
  fetchAdminRoomListGet,
  fetchAdminAdminFaqs,
  fetchAdminGroupChange,
  // fetchAdminAdminFeedbackTexts,
  fetchAdminAdminFeedbackLikes,
  fetchAdminAdminFaqAdd,
  fetchAdminTestFeedbacks,
  fetchAdminAdminKeywords,
  fetchAdminAdminKeywordAdd,
  fetchAdminSignUpRequests,
  fetchAdminUserEamilSend,
  fetchAdminRoomGetUser,
  fetchAdminChatDetail,
  fetchAdminServiceListGet,
  fetchAdminServiceUpdate,
  fetchAdminUserCreateByAdmin,
  fetchAdminAdminVesselListGet,
  fetchAdminVesselAdd,
  fetchAdminVesselDelete,
  fetchAdminAdminPicListGet,
  fetchAdminPicAdd,
  fetchAdminPicDelete,
  fetchAdminPicUpload,
  fetchAdminUserDelete,
  fetchAdminUserReSendEmail,
  fetchAdminUserInviteEmail,
  fetchAdminCompanyUpdate,
  fetchAdminCompanyDelete
} from "./adminAPI";
import { store } from "../../store";

export interface SideMenuDedetail {
  label: string;
  icon: string;
  to: string;
}

export interface SideMenu {
  label: string;
  items: SideMenuDedetail[];
}

export interface DashboardData {
  user: any;
  group: any;
  model: any;
  file: any;
  fgroup: any;
  fgroupall: any;
  pgroup: any;
  pgroupFile: any;
  chatbot: any;
  chatbotGroup: any;
  room: any;
  messages: any;
  emailModeList: any;
  apiLog: any;
  feedbackLikes: any;
  faqs: any;
  pics: any;
  vessels: any;
  keywords: any;
  feedbackTests: any;
  servicesReq: any;
  company: any;
  signUpReq: any;
  history: any;
  // feedbackTexts: any;
}

// 모델 정의
export interface adminSliceState {
  index: number[];
  status: "idle" | "loading" | "failed";
  sideMenu: SideMenu[];
  dashboard: DashboardData;
}

const initialState: adminSliceState = {
  index: [0, 0],
  status: "idle",
  sideMenu: [
    // {
    //   label: "Home",
    //   items: [{ label: "Dashboard", icon: "bi bi-menu-button-wide", to: "/" }],
    // },
    {
      label: "관리",
      items: [
        {
          label: "Users",
          icon: "bi bi-menu-button-wide",
          to: "/uikit/formlayout",
        },
        {
          label: "Files",
          icon: "bi bi-menu-button-wide",
          to: "/uikit/input",
        },
        {
          label: "Chatbots",
          icon: "bi bi-menu-button-wide",
          to: "/uikit/input",
        },
        // {
        //   label: "모델",
        //   icon: "bi bi-menu-button-wide",
        //   to: "/uikit/floatlabel",
        // },
        {
          label: "Service",
          icon: "bi bi-menu-button-wide",
          to: "/uikit/invalidstate",
        },
        {
          label: "Logs",
          icon: "bi bi-menu-button-wide",
          to: "/uikit/invalidstate",
        },
        {
          label: "Datas",
          icon: "bi bi-menu-button-wide",
          to: "/uikit/invalidstate",
        },
        {
          label: "Feedbacks",
          icon: "bi bi-menu-button-wide",
          to: "/uikit/invalidstate",
        },
        {
          label: "Model",
          icon: "bi bi-menu-button-wide",
          to: "/uikit/invalidstate",
        },
        // {
        //   label: "유저 가입 신청 관리",
        //   icon: "bi bi-menu-button-wide",
        //   to: "/uikit/invalidstate",
        // },

        // {
        //   label: "rating",
        //   icon: "bi bi-menu-button-wide",
        //   to: "/uikit/invalidstate",
        // },
      ],
    },
  ],
  dashboard: {
    user: null,
    group: null,
    model: null,
    file: null,
    fgroup: null,
    fgroupall: null,
    pgroup: null,
    pgroupFile: null,
    chatbot: null,
    chatbotGroup: null,
    room: null,
    messages: null,
    emailModeList: null,
    apiLog: null,
    feedbackLikes: null,
    faqs: null,
    pics: null,
    vessels: null,
    keywords: null,
    feedbackTests: null,
    servicesReq: null,
    company: null,
    signUpReq: null,
    history: null,
    // feedbackTexts: null,
  },
};

export interface AdminGet {
  token: string;
}

export interface AdminMessageGet extends AdminGet {
  user_id: number;
}

export interface AdminCompanyChange extends AdminGet {
  user_id: number;
  company_id: number;
}
export interface AdminCompanyUpdate extends AdminGet {
  company_id: number;
  company_name: string;
}
export interface AdminCompanyDelete extends AdminGet {
  company_id: number;
}

export interface AdminGroupChange extends AdminGet {
  user_id: number;
  group_id: number;
}

export interface AdminUserEmailSend extends AdminGet {
  login_id: string;
  email: string;
  company_name: string;
  request_id: number;
}

export interface AdminUserCreateByAdmin extends AdminGet {
  login_id: string;
  email: string;
  name: string;
  vessel_name: string;
  vessel_contact: string;
  company_id: number;
  group_id: number;
}

export interface AdminUserCompanyAdd extends AdminGet {
  company: string;
}

export interface AdminUserSignUpRequest extends AdminGet {
  is_email_sent: string;
}

export interface AdminFaqRequest extends AdminGet {
  faq_question: string;
  faq_manual: string;
  faq_page: number;
  chatbotId: number;
}
export interface AdminKeywordRequest extends AdminGet {
  keyword: string;
}
export interface AdminGroupAdd extends AdminGet {
  groupName: string;
  groupDesc: string;
  is_admin: boolean;
  is_chatbot_ts: boolean;
  is_chatbot_mt: boolean;
  is_chatbot_gi: boolean;
  is_main_manual: boolean;
  is_main_video: boolean;
}
export interface AdminGroupUpdate extends AdminGet {
  groupId: number;
  groupName: string;
  groupDesc: string;
  is_admin: boolean;
  is_chatbot_ts: boolean;
  is_chatbot_mt: boolean;
  is_chatbot_gi: boolean;
  is_main_manual: boolean;
  is_main_video: boolean;
}
export interface AdminGroupDelete extends AdminGet {
  groupId: number;
}

export interface AdminModelAdd extends AdminGet {
  modelName: string;
  version: string;
  description: string;
  modelMethod: string;
}
export interface AdminModelUpdate extends AdminGet {
  modelId: number;
  modelName: string;
  version: string;
  description: string;
  modelMethod: string;
}
export interface AdminUserUpdate extends AdminGet {
  userId: number;
  groupId?: number;
  name?: string;
  email?: string;
  vesselName?: string;
  phoneNumber?: string;
  companyId?: number;
  is_locked?: boolean;
}
export interface AdminUserDelete extends AdminGet {
  userId: number;
}
export interface AdminModelDelete extends AdminGet {
  modelId: number;
}
export interface AdminFileGetOnfid extends AdminGet {
  fgroupId: number;
}
export interface AdminFileAdd extends AdminGet {
  files: any[];
  mode: string;
}
export interface AdminFileUpdate extends AdminGet {
  fileId: number;
  fileName: string;
}
export interface AdminFileDelete extends AdminGet {
  fileId: number;
}
export interface AdminUserEmailLockUpdate extends AdminGet {
  userId: number;
}

export interface AdminFGroupAdd extends AdminGet {
  fgroup_name: string;
  fgroup_pid: number;
}
export interface AdminFGroupUpdate extends AdminGet {
  fgroupId: number;
  fgroupName: string;
}
export interface AdminFGroupDelete extends AdminGet {
  fgroupId: number;
}

export interface AdminPGroupAdd extends AdminGet {
  pgroupName: string;
}
export interface AdminPGroupUpdate extends AdminGet {
  pgroupId: number;
  pgroupName: string;
}
export interface AdminPGroupDelete extends AdminGet {
  pgroupId: number;
}
export interface AdminPGroupFileGet extends AdminGet {
  pgroup_id: number;
}
export interface AdminPGroupFileAdd extends AdminGet {
  pgroup_id: number;
  fids: number[];
}

export interface AdminChatbotAdd extends AdminGet {
  pgroupId: number;
  modelId: number;
}
export interface AdminChatbotDelete extends AdminGet {
  chatbotId: number;
}

export interface AdminChatbotGroupAdd extends AdminGet {
  cgroup_name: string;
  cgroup_type: string;
  chatbot_en_id: number;
  group_id: number;
}
export interface AdminChatbotGroupUpdate extends AdminGet {
  cgroup_id: number;
  cgroup_name: string;
}
export interface AdminChatbotGroupDelete extends AdminGet {
  cgroup_id: number;
}

export interface AdminFaqDelete extends AdminGet {
  faq_id: number;
}

export interface AdminKeywordDelete extends AdminGet {
  keyword_id: number;
}

export interface AdminServiceReqUpdate extends AdminGet {
  service_req_id: number;
  status: string;
}
export interface AdminPicRequest extends AdminGet {
  pic_name: string;
}
export interface AdminPicDelete extends AdminGet {
  pic_id: number;
}
export interface AdminVesselRequest extends AdminGet {
  vessel_name: string;
}
export interface AdminVesselDelete extends AdminGet {
  vessel_id: number;
}
// export interface AdminChatbotAdd extends AdminGet {
//   pgroupId: number;
//   modelId: number;
// }
// export interface AdminChatbotDelete extends AdminGet {
//   chatbotId: number;
// }

export const adminSlice = createAppSlice({
  name: "adminPage",
  initialState,
  reducers: (create) => ({
    adminUserSignUpRequestGet: create.asyncThunk(
      async (adminGetData: AdminUserSignUpRequest) => {
        const response = await fetchAdminSignUpRequests(adminGetData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.signUpReq = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminUserEmailSend: create.asyncThunk(
      async (adminEamilData: AdminUserEmailSend) => {
        const response = await fetchAdminUserEamilSend(adminEamilData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.userServices = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminFaqList: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminAdminFaqs(adminGetData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.faqs = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminFaqAdd: create.asyncThunk(
      async (adminFaqRequestData: AdminFaqRequest) => {
        const response = await fetchAdminAdminFaqAdd(adminFaqRequestData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.faqs = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminFaqDelete: create.asyncThunk(
      async (adminDeleteData: AdminFaqDelete) => {
        const response = await fetchAdminFaqDelete(adminDeleteData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";

    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminFaqFinetune: create.asyncThunk(
      async (adminGetData: 
        {
          token: string,
          ctype: string
        }) => {
        const response = await fetchAdminFaqFinetune(adminGetData, adminGetData.ctype);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminVesselList: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminAdminVesselListGet(adminGetData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
          state.dashboard!.vessels = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminVesselAdd: create.asyncThunk(
      async (adminVesselRequestData: AdminVesselRequest) => {
        const response = await fetchAdminVesselAdd(adminVesselRequestData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.faqs = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminVesselDelete: create.asyncThunk(
      async (adminDeleteData: AdminVesselDelete) => {
        const response = await fetchAdminVesselDelete(adminDeleteData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";

    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminPicList: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminAdminPicListGet(adminGetData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
          state.dashboard!.pics = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminPicAdd: create.asyncThunk(
      async (adminPicRequestData: AdminPicRequest) => {
        const response = await fetchAdminPicAdd(adminPicRequestData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.faqs = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminPicDelete: create.asyncThunk(
      async (adminDeleteData: AdminPicDelete) => {
        const response = await fetchAdminPicDelete(adminDeleteData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";

    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminPicUpload: create.asyncThunk(
      async (adminPicUploadData: {
        token: string;
        fileData: FormData;
      }) => {
        const response = await fetchAdminPicUpload(adminPicUploadData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminKeywordList: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminAdminKeywords(adminGetData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.keywords = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminTestFeedbacks: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminTestFeedbacks(adminGetData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.feedbackTests = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminFileUpload: create.asyncThunk(
      async (fileUploadData: {
        token: string;
        fileData: FormData;
        ctype?: string;
        // chatbotId: number;
      }) => {
        const response = await fetchAdminFileUpload(fileUploadData);
        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
        },
        rejected: (state) => {
          state.status = "failed";
        },
      }
    ),
    

    adminFileKeywordUpload: create.asyncThunk(
      async (fileUploadData: {
        token: string;
        fileData: FormData;
        ctype?: string;
        // chatbotId: number;
      }) => {
        const response = await fetchAdminFileKeywordUpload(fileUploadData);
        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
        },
        rejected: (state) => {
          state.status = "failed";
        },
      }
    ),
    adminKeywordAdd: create.asyncThunk(
      async (adminKeywordRequestData: AdminKeywordRequest) => {
        const response = await fetchAdminAdminKeywordAdd(
          adminKeywordRequestData
        );
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.faqs = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    // adminFeedbackTexts: create.asyncThunk(
    //   async (adminGetData: AdminGet) => {
    //     const response = await fetchAdminAdminFeedbackTexts(adminGetData);
    //     return response;
    //   },
    //   {
    //     pending: (state) => {
    //       state.status = "loading";
    //     },
    //     fulfilled: (state, action) => {
    //       state.status = "idle";
    //       state.dashboard!.feedbackTexts = action.payload;
    //     },
    //     rejected: (state) => {
    //       state.status = "failed";
    //     },
    //   }
    // ),
    adminFeedbackLikes: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminAdminFeedbackLikes(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.feedbackLikes = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminUserUpdate: create.asyncThunk(
      async (adminUserUpdateData: AdminUserUpdate) => {
        const response = await fetchAdminUserUpdate(adminUserUpdateData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminUserDelete: create.asyncThunk(
      async (adminUserDeleteData: AdminUserDelete) => {
        const response = await fetchAdminUserDelete(adminUserDeleteData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminEmailUpdate: create.asyncThunk(
      async (adminUserEmailUpdateData: AdminUserEmailLockUpdate) => {
        const response = await fetchAdminEmailUpdate(adminUserEmailUpdateData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminUserLockUpdate: create.asyncThunk(
      async (adminUserEmailUpdateData: AdminUserEmailLockUpdate) => {
        const response = await fetchAdminLockUpdate(adminUserEmailUpdateData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminGroupChange: create.asyncThunk(
      async (data: AdminGroupChange) => {
        const response = await fetchAdminGroupChange(data);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.company = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminCompanyChange: create.asyncThunk(
      async (data: AdminCompanyChange) => {
        const response = await fetchAdminCompanyChange(data);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.company = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminCompanyAdd: create.asyncThunk(
      async (adminCompanyData: AdminUserCompanyAdd) => {
        const response = await fetchAdminCompanyAdd(adminCompanyData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.company = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminCompanyGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminCompany(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.company = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminCompanyUpdate: create.asyncThunk(
      async (adminUpdateData: AdminCompanyUpdate) => {
        const response = await fetchAdminCompanyUpdate(adminUpdateData);
        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
        },
        rejected: (state) => {
          state.status = "failed";
        },
      }
    ),
    adminCompanyDelete: create.asyncThunk(
      async (adminDeleteData: AdminCompanyDelete) => {
        const response = await fetchAdminCompanyDelete(adminDeleteData);
        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
        },
        rejected: (state) => {
          state.status = "failed";
        },
      }
    ),
    adminUserAllGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminUserAllGet(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.user = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    sidebarSel: create.reducer((state, action: PayloadAction<number[]>) => {
      state.index = action.payload;
    }),
    adminApilogs: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminApilogs(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.apiLog = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminEmailModeList: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminEmailModeList(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.emailModeList = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminGroupListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminGroupListGet(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.group = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminGroupAdd: create.asyncThunk(
      async (adminAddData: AdminGroupAdd) => {
        const response = await fetchAdminGroupAdd(adminAddData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminGroupDelete: create.asyncThunk(
      async (adminDeleteData: AdminGroupDelete) => {
        const response = await fetchAdminGroupDelete(adminDeleteData);
  
  
        adminGroupListGet(adminDeleteData as AdminGet);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";

    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminGroupUpdate: create.asyncThunk(
      async (adminUpdateData: AdminGroupUpdate) => {
        const response = await fetchAdminGroupUpdate(adminUpdateData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminModelListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminModelListGet(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.model = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminModelAdd: create.asyncThunk(
      async (adminAddData: AdminModelAdd) => {
        const response = await fetchAdminModelAdd(adminAddData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminModelDelete: create.asyncThunk(
      async (adminDeleteData: AdminModelDelete) => {
        const response = await fetchAdminModelDelete(adminDeleteData);
  
  
        adminGroupListGet(adminDeleteData as AdminGet);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";

    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminModelUpdate: create.asyncThunk(
      async (adminUpdateData: AdminModelUpdate) => {
        const response = await fetchAdminModelUpdate(adminUpdateData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminFileListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminFileListGet(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.file = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminFileListGetOnfid: create.asyncThunk(
      async (adminGetData: AdminFileGetOnfid) => {
        const response = await fetchAdminFileListGetOnfid(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.fgroup!.dirFile = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminFileAdd: create.asyncThunk(
      async (adminAddData: AdminFileAdd) => {
        const response = await fetchAdminFileAdd(adminAddData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminFileUpdate: create.asyncThunk(
      async (adminAddData: AdminFileUpdate) => {
        const response = await fetchAdminFileUpdate(adminAddData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminFileDelete: create.asyncThunk(
      async (adminDeleteData: AdminFileDelete) => {
        const response = await fetchAdminFileDelete(adminDeleteData);
  
  
        adminGroupListGet(adminDeleteData as AdminGet);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";

    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminFGroupAllListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminFGroupAllListGet(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
          // console.log(action.payload);
          state.dashboard!.fgroupall = {
            tree: action.payload.file_tree,
            table: action.payload.fgroup,
          };
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminFGroupListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminFGroupListGet(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
          // console.log(action.payload);
          state.dashboard!.fgroup = {
            tree: action.payload.file_tree,
            table: action.payload.fgroup,
          };
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminFGroupAdd: create.asyncThunk(
      async (adminAddData: AdminFGroupAdd) => {
        const response = await fetchAdminFGroupAdd(adminAddData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminFGroupDelete: create.asyncThunk(
      async (adminDeleteData: AdminFGroupDelete) => {
        const response = await fetchAdminFGroupDelete(adminDeleteData);
  
  
        adminGroupListGet(adminDeleteData as AdminGet);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";

    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminFGroupUpdate: create.asyncThunk(
      async (adminUpdateData: AdminFGroupUpdate) => {
        const response = await fetchAdminFGroupUpdate(adminUpdateData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminPGroupListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminPGroupListGet(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.pgroup = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminPGroupAdd: create.asyncThunk(
      async (adminAddData: AdminPGroupAdd) => {
        const response = await fetchAdminPGroupAdd(adminAddData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminPGroupDelete: create.asyncThunk(
      async (adminDeleteData: AdminPGroupDelete) => {
        const response = await fetchAdminPGroupDelete(adminDeleteData);
  
  
        adminGroupListGet(adminDeleteData as AdminGet);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";

    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminPGroupUpdate: create.asyncThunk(
      async (adminUpdateData: AdminPGroupUpdate) => {
        const response = await fetchAdminPGroupUpdate(adminUpdateData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminPGroupFileListGet: create.asyncThunk(
      async (adminGetData: AdminPGroupFileGet) => {
        const response = await fetchAdminPGroupFileListGet(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.pgroupFile = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminPGroupFileAdd: create.asyncThunk(
      async (adminAddData: AdminPGroupFileAdd) => {
        const response = await fetchAdminPGroupFileAdd(adminAddData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminChatbotListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminChatbotListGet(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.chatbot = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminChatbotAdd: create.asyncThunk(
      async (adminAddData: AdminChatbotAdd) => {
        const response = await fetchAdminChatbotAdd(adminAddData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminChatbotDelete: create.asyncThunk(
      async (adminDeleteData: AdminChatbotDelete) => {
        const response = await fetchAdminChatbotDelete(adminDeleteData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";

    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminChatbotGroupListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminChatbotGroupListGet(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.chatbotGroup = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminChatbotGroupAdd: create.asyncThunk(
      async (adminAddData: AdminChatbotGroupAdd) => {
        const response = await fetchAdminChatbotGroupAdd(adminAddData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),
    adminChatbotGroupUpdate: create.asyncThunk(
      async (adminUpdateData: AdminChatbotGroupUpdate) => {
        const response = await fetchAdminChatbotGroupUpdate(adminUpdateData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminChatbotGroupDelete: create.asyncThunk(
      async (adminDeleteData: AdminChatbotGroupDelete) => {
        const response = await fetchAdminChatbotGroupDelete(adminDeleteData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";

    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminKeywordDelete: create.asyncThunk(
      async (adminDeleteData: AdminKeywordDelete) => {
        const response = await fetchAdminKeywordDelete(adminDeleteData);
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";

    
          // state.dashboard!.group = action.payload
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    

    adminRoomListGet: create.asyncThunk(
      async (adminGetData: AdminGet) => {
        const response = await fetchAdminRoomListGet(adminGetData);
  
  
        return response;
      },
      {
        pending: (state) => {
    
          state.status = "loading";
        },
        fulfilled: (state, action) => {
    
          state.status = "idle";
    
          state.dashboard!.room = action.payload;
        },
        rejected: (state) => {
    
          state.status = "failed";
        },
      }
    ),

    adminRoomGetUser: create.asyncThunk(
      async (data: AdminMessageGet) => {
        const response = await fetchAdminRoomGetUser(data);
  

        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";

          const newSidebar = {
            label: "BWMS Troubleshooting",
            chatList: action.payload.reverse()
          };
          state.dashboard.history = [newSidebar];
        },
        rejected: (state) => {
          state.status = "failed";
        },
      }
    ),
    adminChatDetail: create.asyncThunk(
      async (data: {token: string, room_id: number}) => {
        const response = await fetchAdminChatDetail(data);
  

        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
          state.dashboard.messages = {
            room_id: action.payload[0].room_id,
            room_name: action.payload[0].room_name,
            messages: action.payload[0].messages.map((item: any, index: number) => {
              let type = "basic";
              let hasImg = false;
              if (item.message_type == "image_url") {
                type = "image";
                hasImg = true;
              }

              return  {
                _id: item.message_id,
                type: type,
                isBot: item.is_chatbot,
                hasDoc: item.message_pdf_name ? true : false,
                hasImg: hasImg,
                reference: null,
                text: item.message_formatted_text,
                imageUrl: item.message_base64,
                sendTime: item.created_at,
                feedback: item.feedback,
              };
            })
          }
        },
        rejected: (state) => {
          state.status = "failed";
        },
      }
    ),

    adminServiceListGet: create.asyncThunk(
      async (data: AdminGet) => {
        const response = await fetchAdminServiceListGet(data);
  

        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
          
        },
        fulfilled: (state, action) => {
          state.status = "idle";
          state.dashboard!.servicesReq = action.payload;
        },
        rejected: (state) => {
          state.status = "failed";
        },
      }
    ),
    adminServiceUpdate: create.asyncThunk(
      async (adminUpdateData: AdminServiceReqUpdate) => {
        const response = await fetchAdminServiceUpdate(adminUpdateData);
        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
        },
        rejected: (state) => {
          state.status = "failed";
        },
      }
    ),

    resetAdmin: create.reducer((state) => {
      return initialState;
    }),
    
    adminUserCreateByAdmin: create.asyncThunk(
      async (adminUpdateData: AdminUserCreateByAdmin) => {
        const response = await fetchAdminUserCreateByAdmin(adminUpdateData);
        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
        },
        rejected: (state) => {
          state.status = "failed";
        },
      }
    ),

    
    adminUserReSendEmail: create.asyncThunk(
      async (adminUpdateData: {token: string, userId: number}) => {
        const response = await fetchAdminUserReSendEmail(adminUpdateData);
        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
        },
        rejected: (state) => {
          state.status = "failed";
        },
      }
    ),
    adminUserInviteEmail: create.asyncThunk(
      async (adminUpdateData: {token: string, userId: number}) => {
        const response = await fetchAdminUserInviteEmail(adminUpdateData);
        return response;
      },
      {
        pending: (state) => {
          state.status = "loading";
        },
        fulfilled: (state, action) => {
          state.status = "idle";
        },
        rejected: (state) => {
          state.status = "failed";
        },
      }
    ),

    // adminRoomAdd: create.asyncThunk(
    //   async (adminAddData: AdminRoomAdd) => {
    //     const response = await fetchAdminRoomAdd(adminAddData);
    //     return response;
    //   },
    //   {
    //     pending: (state) => {
    //       state.status = "loading";
    //     },
    //     fulfilled: (state, action) => {
    //       state.status = "idle";
    //       // state.dashboard!.group = action.payload
    //     },
    //     rejected: (state) => {
    //       state.status = "failed";
    //     },
    //   },
    // ),
    // adminRoomDelete: create.asyncThunk(
    //   async (adminDeleteData: AdminRoomDelete) => {
    //     const response = await fetchAdminRoomDelete(adminDeleteData);
    //     return response;
    //   },
    //   {
    //     pending: (state) => {
    //       state.status = "loading";
    //     },
    //     fulfilled: (state, action) => {
    //       state.status = "idle";

    //       // state.dashboard!.group = action.payload

    //     },
    //     rejected: (state) => {
    //       state.status = "failed";
    //     },
    //   },
    // ),
  }),

  selectors: {
    selectIndex: (state) => state.index,
    selectSideMenu: (state) => state.sideMenu,
    selectDashboardUser: (state) => state.dashboard!.user,
    selectDashboardGroup: (state) => state.dashboard!.group,
    selectDashboardModel: (state) => state.dashboard!.model,
    selectDashboardFile: (state) => state.dashboard!.file,
    selectDashboardFGroup: (state) => state.dashboard!.fgroup,
    selectDashboardFGroupAll: (state) => state.dashboard!.fgroupall,
    selectDashboardPGroup: (state) => state.dashboard!.pgroup,
    selectDashboardPGroupFile: (state) => state.dashboard!.pgroupFile,
    selectDashboardChatbot: (state) => state.dashboard!.chatbot,
    selectDashboardChatbotGroup: (state) => state.dashboard!.chatbotGroup,
    selectDashboardRoom: (state) => state.dashboard!.room,
    selectDashboardEmailModeList: (state) => state.dashboard!.emailModeList,
    selectDashboardApiLog: (state) => state.dashboard!.apiLog,
    selectDashboardFeedbackLike: (state) => state.dashboard!.feedbackLikes,
    selectDashboardFaqs: (state) => state.dashboard!.faqs,
    selectDashboardKeywords: (state) => state.dashboard!.keywords,
    selectDashboardTestFeedbacks: (state) => state.dashboard!.feedbackTests,
    selectDashboardUserServices: (state) => state.dashboard!.servicesReq,
    selectDashboardCompany: (state) => state.dashboard!.company,
    selectDashboardSignUpRequest: (state) => state.dashboard!.signUpReq,
    selectDashboardRoomGet: (state) => state.dashboard!.history,
    selectDashboardChatDetail: (state) => state.dashboard!.messages,
    // selectDashboardFeedbackTexts: (state) => state.dashboard!.feedbackTexts,
  },
});

export const {
  resetAdmin,
  adminUserAllGet,
  adminGroupListGet,
  adminGroupAdd,
  adminGroupChange,
  adminGroupDelete,
  adminGroupUpdate,
  adminModelListGet,
  adminModelAdd,
  adminModelDelete,
  adminModelUpdate,
  adminFileListGet,
  adminFileAdd,
  adminFileUpdate,
  adminFileDelete,
  adminUserUpdate,
  adminFGroupListGet,
  adminFGroupAllListGet,
  adminFileListGetOnfid,
  adminFGroupAdd,
  adminFGroupDelete,
  adminFGroupUpdate,
  
  adminPGroupListGet,
  adminPGroupAdd,
  adminPGroupDelete,
  adminPGroupUpdate,
  adminUserLockUpdate,
  adminEmailUpdate,
  adminPGroupFileListGet,
  adminPGroupFileAdd,
  adminUserDelete,
  adminChatbotListGet,
  adminChatbotAdd,
  adminChatbotDelete,
  adminChatbotGroupListGet,
  adminChatbotGroupAdd,
  adminChatbotGroupUpdate,
  adminChatbotGroupDelete,
  adminApilogs,
  adminRoomListGet,
  adminEmailModeList,
  adminKeywordDelete,
  adminFaqFinetune,
  adminFeedbackLikes,
  adminFileUpload,
  adminFaqList,
  adminFaqAdd,
  adminFaqDelete,

  adminPicUpload,
  adminPicList,
  adminPicAdd,
  adminPicDelete,
  adminVesselList,
  adminVesselAdd,
  adminVesselDelete,
  
  adminKeywordList,
  adminFileKeywordUpload,
  adminKeywordAdd,
  adminUserSignUpRequestGet,
  adminCompanyGet,
  adminCompanyAdd,
  adminCompanyUpdate,
  adminCompanyDelete,
  adminCompanyChange,
  
  adminUserEmailSend,
  adminUserReSendEmail,
  adminUserInviteEmail,
  
  adminRoomGetUser,
  adminChatDetail,
  
  // adminFeedbackTexts,
  sidebarSel,
  adminTestFeedbacks,
  adminServiceListGet,
  adminServiceUpdate,
  adminUserCreateByAdmin,
} = adminSlice.actions;
export const {
  selectIndex,
  selectSideMenu,
  selectDashboardCompany,
  selectDashboardUser,
  selectDashboardGroup,
  selectDashboardModel,
  selectDashboardFile,
  selectDashboardApiLog,
  selectDashboardKeywords,
  selectDashboardFGroup,
  selectDashboardPGroup,
  selectDashboardPGroupFile,
  selectDashboardFGroupAll,
  selectDashboardChatbot,
  selectDashboardChatbotGroup,
  selectDashboardRoom,
  selectDashboardEmailModeList,
  selectDashboardFeedbackLike,
  selectDashboardFaqs,
  selectDashboardTestFeedbacks,
  selectDashboardUserServices,
  selectDashboardSignUpRequest,
  selectDashboardRoomGet,
  // selectDashboardFeedbackTexts,
  selectDashboardChatDetail,
} = adminSlice.selectors;

// reducer도 export
//  default adminSlice.reducer;
export default adminSlice;
